.breadcrumb {
  font-size: 1.1875rem;
  .breadcrumb-item {
    &.active {
      a {
        color: var(--primary);
      }
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: var(--primary);
  }
}

.page-titles {
  padding: 0.9375rem 2.5rem;
  background: $white;
  margin-bottom: 1.875rem;
  border-radius: 1rem;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  @at-root [data-theme-version="dark"] & {
    background: $dark-card;
  }

  @include respond("tab-land") {
    margin-top: -1.875rem;
  }
  @include respond("phone-land") {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0.9375rem;
    padding: 0.9375rem 1.25rem;
    margin-top: 0;
  }

  .justify-content-sm-end {
    align-items: center;
  }

  h4 {
    margin-bottom: 0;
    margin-top: 0;
    color: var(--primary);
    font-size: 1.25rem;
    span {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
  .breadcrumb {
    margin-bottom: 0;
    padding: 0;
    background: transparent;
    // font-size: 0.875rem;
    li {
      margin-top: 0;
      margin-bottom: 0;

      a {
        color: $l-ctl;
        @include respond("phone") {
          font-size: 0.75rem;
        }
      }

      &.active {
        color: var(--primary);
        font-weight: 600;
        a {
          color: $secondary;
        }
      }
    }

    .breadcrumb-item + .breadcrumb-item {
      &:before {
        //content: "\e606";
        /* font-family: 'simple-line-icons';
                font-weight: 400;
				position:absolute;
                font-size: 10px; */
        color: $secondary;
      }
    }

    &-datepicker {
      font-size: 0.75rem;
      color: $muted;

      &__icon {
        font-size: 0.875rem;
      }
    }
  }

  .breadcrumb-widget {
    .border-dark {
      border-color: $gray-300 !important;
    }
    h4 {
      color: $strong;
      font-weight: 600;
    }
    @include respond("phone") {
      text-align: left !important;
      margin-bottom: 0.9375rem;
    }
  }
}
