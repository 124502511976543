.form-control.input-search {
  height: 38px;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.input-search {
  height: 38px;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  border: 1px solid #eee;
  padding: 0 8px;
}

.filtering-table thead th:first-child {
  width: 70px;
}
.table tfoot tr {
  border-bottom-width: 1px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-color: #eeeeee;
}

.filter-pagination {
  display: inline-block;
  border-radius: 10px;
  padding: 5px 5px;
}
.filter-pagination .previous-button,
.filter-pagination .next-button {
  font-size: 16px;
  border: 0 !important;
  width: auto;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 10px;
  display: inline-block;
  background-color: #fff;
  color: #216fed;
}
.filter-pagination button:hover {
  background: #216fed;
  color: #fff;
}
.filter-pagination button[disabled] {
  opacity: 0.5;
  cursor: default;
}
.filter-pagination button[disabled]:hover {
  background: #216fed;
  opacity: 0.5;
  color: #fff;
}
.table-index input[type="number"] {
  background: #f0f0f0 !important;
  color: #969ba0;
  width: 70px;
  border: 0;
  background-color: #f8f8f8;
  padding: 6px 0 6px 10px;
}
@media only screen and (max-width: 575px) {
  .filter-pagination .previous-button,
  .filter-pagination .next-button {
    padding: 8px 10px;
    font-size: 14px;
  }
}
