.customers {
  display: flex;
  img {
    height: 5.9375rem;
    width: 5.9375rem;
    border-radius: 1.25rem;
  }
  @include custommq($max: 100rem) {
    margin-bottom: 1.25rem;
    img {
      height: 5rem;
      width: 5rem;
    }
  }
}
.media-body {
  flex: 1;
}
.media-dropdown {
  .dropdown-menu {
    width: 30rem;
    padding: 1.875rem;
  }
}
.page-indicator {
  a {
    padding: 0.9375rem 1.25rem;
    @include respond("phone") {
      padding: 0.625rem 0.625rem;
    }
  }
}
.pagination {
  .page-link {
    border-radius: 100%;
    margin: 0 0.3125rem;
    height: 3.125rem;
    width: 3.125rem;
    line-height: 2.125rem;
    @include respond("phone") {
      height: 2.5rem;
      width: 2.5rem;
      line-height: 1.5rem;
    }
  }
}
.contacts-list-area {
  .card {
    height: auto;
    margin-bottom: 1.25rem;
  }
}
.text-lg-center {
  small {
    font-size: 16px;
  }
}
