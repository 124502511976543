.picker {
  .picker__frame {
    min-width: 28.125rem;
    max-width: 28.125rem;

    .picker__box {
      padding: 1.25rem;
      border: 0;
      box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.1);

      .picker__header {
        margin-top: 0;

        .picker__year {
          color: var(--primary);
          font-style: inherit;
          font-size: 1.25rem;
        }

        .picker__nav--prev,
        .picker__nav--next {
          width: 2.5rem;
          height: 2.5rem;
          padding: 0;
          line-height: 2.5rem;
          border-radius: 0.125rem;
          &:before {
            content: none !important;
          }
          &:after {
            font-family: fontawesome;
            border: 0;
            margin: 0 auto;
            font-size: 1.375rem;
          }
          &:hover {
            background-color: var(--primary);
            color: var(--primary);
          }
        }
        .picker__nav--prev {
          left: 0;
          &:after {
            content: "\f0d9";
          }
        }
        .picker__nav--next {
          right: 0;
          &:after {
            content: "\f0da";
          }
        }
      }

      .picker__table {
        .picker__weekday {
          padding: 0.625rem 0;
          font-size: 1rem;
        }
        .picker__day {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 3.125rem;
          padding: 0 !important;
          font-size: 1rem;
          line-height: 2.5rem;
          margin: auto;
          border: 0 !important;

          &.picker__day--today {
            &:before {
              content: none !important;
            }
          }

          &.picker__day--highlighted {
            border: 0 !important;
            padding: 0;
            background-color: var(--primary);
            color: $white !important;
          }

          &:hover {
            background-color: var(--primary);
            color: $white !important;
          }
        }
      }

      .picker__footer {
        .picker__button--today {
          background-color: var(--primary);
          color: $white !important;

          &:before {
            content: none !important;
          }
        }

        .picker__button--clear,
        .picker__button--close,
        .picker__button--today {
          border: 0;
          border-radius: 0.125rem;
          font-size: 1rem;
          &:hover {
            background-color: var(--primary);
            color: $white !important;
          }
        }
      }
    }

    @include respond("phone") {
      min-width: 100% !important;
      max-width: 100% !important;

      .picker__box {
        padding: 0.9375rem;
        margin: 0 0.625rem;
      }
    }
  }
}
