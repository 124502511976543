/* Custom styles for the nodes */
.custom-node {
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  display: inline-block;
  width: 200px;
  white-space: normal;
  word-wrap: break-word;
}

.grey-node {
  background-color: lightgrey;
  color: red;
  font-weight: bold;
  border: 1px solid red;
}

.green-node {
  background-color: #6ec081;
  color: #000;
  font-weight: bold;
}

.amber-node {
  background-color: #ffc107;
  color: #000;
  font-weight: bold;
}

.red-node {
  background-color: #dc3545;
}

.black-node {
  background-color: #343a40;
  color: #fff;
}

.blue-node {
  background-color: #53b2e8;
  color: #000;
  font-weight: bold;
}

.circular-node {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.custom-node2 {
  padding: 10px;
  border: none;
  font-size: 12px;
  text-align: center;
  color: red;
  background-color: transparent;
  cursor: none;
}
