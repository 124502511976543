@import "../../abstracts/variable";

.noUi-target {
  border-color: transparent;
  border-radius: 0;
}

.noUi-connect {
  background-color: var(--primary);

  &s {
    background-color: $grey;
  }

  &.c-1-color {
    background-color: $success;
  }

  &.c-2-color {
    background-color: $info;
  }

  &.c-3-color {
    background-color: var(--primary);
  }

  &.c-4-color {
    background-color: $warning;
  }
}

.noUi-vertical {
  width: 0.375rem;
}

.noUi-horizontal {
  height: 0.125rem;
  border: 0;
  margin-bottom: 0.625rem;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  height: 0.9375rem;
  width: 0.9375rem;
  border-radius: 3.125rem;
  box-shadow: none;
  border: none;
  background-color: var(--primary);

  &::after,
  &::before {
    display: none;
  }
}
.noUi-vertical .noUi-handle {
  left: -0.25rem;
  top: -0.375rem;
}
.noUi-horizontal .noUi-handle {
  top: -0.4375rem;
  cursor: pointer;
}
html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -0.375rem;
}
#slider-toggle {
  height: 3.125rem;
}
#slider-toggle.off .noUi-handle {
  border-color: var(--primary);
}

.colorpicker-slider {
  .sliders.noUi-target {
    &#red,
    &#green,
    &#blue {
      margin: 0.625rem;
      display: inline-block;
      height: 12.5rem;
    }

    &#red .noUi-connect {
      background: #c0392b;
    }

    &#green .noUi-connect {
      background: #27ae60;
    }

    &#blue .noUi-connect {
      background: #2980b9;
    }
  }

  #result {
    margin: 3.75rem 1.625rem;
    height: 6.25rem;
    width: 6.25rem;
    display: inline-block;
    vertical-align: top;
    color: rgb(127, 127, 127);
    background: rgb(127, 127, 127);
    border: 0.0625rem solid #fff;
    box-shadow: 0 0 0.625rem;
  }
}

.slider-vertical {
  height: 18rem;
}
