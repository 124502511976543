@import "../../abstracts/variable";

.clipboard-btn {
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: var(--primary);
    color: $white;
  }
}
