@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot");
  src: url("fonts/icomoon.eot") format("embedded-opentype"),
    url("fonts/icomoon.ttf") format("truetype"),
    url("fonts/icomoon.woff") format("woff"),
    url("fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* font-family: 'icomoon' !important; */
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-Angle-Grinder .path1:before {
  content: "\e900";

  opacity: 0.3;
}
.icon-Angle-Grinder .path2:before {
  content: "\e901";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Angle-Grinder .path3:before {
  content: "\e902";
  margin-left: -1em;
}
.icon-Axe .path1:before {
  content: "\e903";

  opacity: 0.3;
}
.icon-Axe .path2:before {
  content: "\e904";
  margin-left: -1em;
}
.icon-Brush .path1:before {
  content: "\e905";

  opacity: 0.3;
}
.icon-Brush .path2:before {
  content: "\e906";
  margin-left: -1em;
}
.icon-Compass .path1:before {
  content: "\e907";

  opacity: 0.3;
}
.icon-Compass .path2:before {
  content: "\e908";
  margin-left: -1em;
}
.icon-Hummer .path1:before {
  content: "\e909";

  opacity: 0.3;
}
.icon-Hummer .path2:before {
  content: "\e90a";
  margin-left: -1em;
}
.icon-Hummer .path3:before {
  content: "\e90b";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Hummer1 .path1:before {
  content: "\e90c";

  opacity: 0.3;
}
.icon-Hummer1 .path2:before {
  content: "\e90d";
  margin-left: -1em;
}
.icon-Pantone .path1:before {
  content: "\e90e";

  opacity: 0.3;
}
.icon-Pantone .path2:before {
  content: "\e90f";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Pantone .path3:before {
  content: "\e910";
  margin-left: -1em;
}
.icon-Road-Cone .path1:before {
  content: "\e911";
}
.icon-Road-Cone .path2:before {
  content: "\e912";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Roller .path1:before {
  content: "\e913";
}
.icon-Roller .path2:before {
  content: "\e914";
  margin-left: -1em;
}
.icon-Roller .path3:before {
  content: "\e915";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Roulette .path1:before {
  content: "\e916";
}
.icon-Roulette .path2:before {
  content: "\e917";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Screwdriver .path1:before {
  content: "\e918";

  opacity: 0.3;
}
.icon-Screwdriver .path2:before {
  content: "\e919";
  margin-left: -1em;
}
.icon-Shovel1 .path1:before {
  content: "\e91a";

  opacity: 0.3;
}
.icon-Shovel1 .path2:before {
  content: "\e91b";
  margin-left: -1em;
}
.icon-Spatula .path1:before {
  content: "\e91c";

  opacity: 0.3;
}
.icon-Spatula .path2:before {
  content: "\e91d";
  margin-left: -1em;
}
.icon-Swiss-knife .path1:before {
  content: "\e91e";

  opacity: 0.3;
}
.icon-Swiss-knife .path2:before {
  content: "\e91f";
  margin-left: -1em;
}
.icon-Tools .path1:before {
  content: "\e920";
}
.icon-Tools .path2:before {
  content: "\e921";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Align-auto .path1:before {
  content: "\e922";

  opacity: 0.3;
}
.icon-Align-auto .path2:before {
  content: "\e923";
  margin-left: -1em;
}
.icon-Align-center .path1:before {
  content: "\e924";

  opacity: 0.3;
}
.icon-Align-center .path2:before {
  content: "\e925";
  margin-left: -1em;
}
.icon-Align-justify .path1:before {
  content: "\e926";

  opacity: 0.3;
}
.icon-Align-justify .path2:before {
  content: "\e927";
  margin-left: -1em;
}
.icon-Align-left .path1:before {
  content: "\e928";

  opacity: 0.3;
}
.icon-Align-left .path2:before {
  content: "\e929";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Align-left .path3:before {
  content: "\e92a";
  margin-left: -1em;
}
.icon-Align-right .path1:before {
  content: "\e92b";

  opacity: 0.3;
}
.icon-Align-right .path2:before {
  content: "\e92c";
  margin-left: -1em;
}
.icon-Article .path1:before {
  content: "\e92d";
}
.icon-Article .path2:before {
  content: "\e92e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Bold:before {
  content: "\e92f";
}
.icon-Bullet-list .path1:before {
  content: "\e930";
}
.icon-Bullet-list .path2:before {
  content: "\e931";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Code:before {
  content: "\e932";
}
.icon-Edit-text .path1:before {
  content: "\e933";

  opacity: 0.3;
}
.icon-Edit-text .path2:before {
  content: "\e934";
  margin-left: -1em;
}
.icon-Filter:before {
  content: "\e935";
}
.icon-Font .path1:before {
  content: "\e936";
}
.icon-Font .path2:before {
  content: "\e937";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-H1 .path1:before {
  content: "\e938";
}
.icon-H1 .path2:before {
  content: "\e939";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-H2 .path1:before {
  content: "\e93a";
}
.icon-H2 .path2:before {
  content: "\e93b";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Itallic:before {
  content: "\e93c";
}
.icon-Menu .path1:before {
  content: "\e93d";
}
.icon-Menu .path2:before {
  content: "\e93e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Paragraph:before {
  content: "\e93f";
}
.icon-Quote .path1:before {
  content: "\e940";
}
.icon-Quote .path2:before {
  content: "\e941";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Quote1 .path1:before {
  content: "\e942";
}
.icon-Quote1 .path2:before {
  content: "\e943";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Redo:before {
  content: "\e944";
}
.icon-Strikethrough .path1:before {
  content: "\e945";

  opacity: 0.3;
}
.icon-Strikethrough .path2:before {
  content: "\e946";
  margin-left: -1em;
}
.icon-Text:before {
  content: "\e947";
}
.icon-Text-height .path1:before {
  content: "\e948";

  opacity: 0.3;
}
.icon-Text-height .path2:before {
  content: "\e949";
  margin-left: -1em;
}
.icon-Text-width .path1:before {
  content: "\e94a";

  opacity: 0.3;
}
.icon-Text-width .path2:before {
  content: "\e94b";
  margin-left: -1em;
}
.icon-Underline .path1:before {
  content: "\e94c";
}
.icon-Underline .path2:before {
  content: "\e94d";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Undo:before {
  content: "\e94e";
}
.icon-ATM .path1:before {
  content: "\e94f";

  opacity: 0.3;
}
.icon-ATM .path2:before {
  content: "\e950";
  margin-left: -1em;
}
.icon-Bag .path1:before {
  content: "\e951";

  opacity: 0.3;
}
.icon-Bag .path2:before {
  content: "\e952";
  margin-left: -1em;
}
.icon-Bag1 .path1:before {
  content: "\e953";

  opacity: 0.3;
}
.icon-Bag1 .path2:before {
  content: "\e954";
  margin-left: -1em;
}
.icon-Barcode .path1:before {
  content: "\e955";
}
.icon-Barcode .path2:before {
  content: "\e956";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Barcode-read .path1:before {
  content: "\e957";

  opacity: 0.3;
}
.icon-Barcode-read .path2:before {
  content: "\e958";
  margin-left: -1em;
}
.icon-Barcode-scan .path1:before {
  content: "\e959";
}
.icon-Barcode-scan .path2:before {
  content: "\e95a";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Barcode-scan .path3:before {
  content: "\e95b";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Bitcoin .path1:before {
  content: "\e95c";

  opacity: 0.3;
}
.icon-Bitcoin .path2:before {
  content: "\e95d";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Bitcoin .path3:before {
  content: "\e95e";
  margin-left: -1em;
}
.icon-Box1 .path1:before {
  content: "\e95f";

  opacity: 0.3;
}
.icon-Box1 .path2:before {
  content: "\e960";
  margin-left: -1em;
}
.icon-Box2 .path1:before {
  content: "\e961";
}
.icon-Box2 .path2:before {
  content: "\e962";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Box3 .path1:before {
  content: "\e963";

  opacity: 0.3;
}
.icon-Box3 .path2:before {
  content: "\e964";
  margin-left: -1em;
}
.icon-Calculator .path1:before {
  content: "\e965";

  opacity: 0.3;
}
.icon-Calculator .path2:before {
  content: "\e966";
  margin-left: -1em;
}
.icon-Cart1 .path1:before {
  content: "\e967";

  opacity: 0.3;
}
.icon-Cart1 .path2:before {
  content: "\e968";
  margin-left: -1em;
}
.icon-Cart2 .path1:before {
  content: "\e969";

  opacity: 0.3;
}
.icon-Cart2 .path2:before {
  content: "\e96a";
  margin-left: -1em;
}
.icon-Cart .path1:before {
  content: "\e96b";

  opacity: 0.3;
}
.icon-Cart .path2:before {
  content: "\e96c";
  margin-left: -1em;
}
.icon-Chart-bar .path1:before {
  content: "\e96d";

  opacity: 0.3;
}
.icon-Chart-bar .path2:before {
  content: "\e96e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Chart-bar .path3:before {
  content: "\e96f";
  margin-left: -1em;
}
.icon-Chart-bar .path4:before {
  content: "\e970";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Chart-bar1 .path1:before {
  content: "\e971";

  opacity: 0.3;
}
.icon-Chart-bar1 .path2:before {
  content: "\e972";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Chart-bar1 .path3:before {
  content: "\e973";
  margin-left: -1em;
}
.icon-Chart-bar1 .path4:before {
  content: "\e974";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Chart-bar2 .path1:before {
  content: "\e975";

  opacity: 0.3;
}
.icon-Chart-bar2 .path2:before {
  content: "\e976";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Chart-bar2 .path3:before {
  content: "\e977";
  margin-left: -1em;
}
.icon-Chart-bar2 .path4:before {
  content: "\e978";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Chart-line .path1:before {
  content: "\e979";
}
.icon-Chart-line .path2:before {
  content: "\e97a";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Chart-line1 .path1:before {
  content: "\e97b";
}
.icon-Chart-line1 .path2:before {
  content: "\e97c";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Chart-pie .path1:before {
  content: "\e97d";

  opacity: 0.3;
}
.icon-Chart-pie .path2:before {
  content: "\e97e";
  margin-left: -1em;
}
.icon-Credit-card .path1:before {
  content: "\e97f";

  opacity: 0.3;
}
.icon-Credit-card .path2:before {
  content: "\e980";
  margin-left: -1em;
}
.icon-Credit-card .path3:before {
  content: "\e981";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Dollar .path1:before {
  content: "\e982";

  opacity: 0.3;
}
.icon-Dollar .path2:before {
  content: "\e983";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Dollar .path3:before {
  content: "\e984";
  margin-left: -1em;
}
.icon-Euro .path1:before {
  content: "\e985";

  opacity: 0.3;
}
.icon-Euro .path2:before {
  content: "\e986";
  margin-left: -1em;
}
.icon-Gift .path1:before {
  content: "\e987";
}
.icon-Gift .path2:before {
  content: "\e988";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Loader .path1:before {
  content: "\e989";

  opacity: 0.3;
}
.icon-Loader .path2:before {
  content: "\e98a";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Loader .path3:before {
  content: "\e98b";
  margin-left: -1em;
}
.icon-MC .path1:before {
  content: "\e98c";

  opacity: 0.3;
}
.icon-MC .path2:before {
  content: "\e98d";
  margin-left: -1em;
}
.icon-Money .path1:before {
  content: "\e98e";

  opacity: 0.3;
}
.icon-Money .path2:before {
  content: "\e98f";
  margin-left: -1em;
}
.icon-Pound .path1:before {
  content: "\e990";
}
.icon-Pound .path2:before {
  content: "\e991";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Price:before {
  content: "\e992";
}
.icon-145:before {
  content: "\e992";
}
.icon-Price1:before {
  content: "\e993";
}
.icon-245:before {
  content: "\e993";
}
.icon-Rouble .path1:before {
  content: "\e994";

  opacity: 0.3;
}
.icon-Rouble .path2:before {
  content: "\e995";
  margin-left: -1em;
}
.icon-Safe .path1:before {
  content: "\e996";

  opacity: 0.3;
}
.icon-Safe .path2:before {
  content: "\e997";
  margin-left: -1em;
}
.icon-Sale .path1:before {
  content: "\e998";
}
.icon-Sale .path2:before {
  content: "\e999";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Sale1 .path1:before {
  content: "\e99a";

  opacity: 0.3;
}
.icon-Sale1 .path2:before {
  content: "\e99b";
  margin-left: -1em;
}
.icon-Sale1 .path3:before {
  content: "\e99c";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Sale1 .path4:before {
  content: "\e99d";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Settings .path1:before {
  content: "\e99e";

  opacity: 0.3;
}
.icon-Settings .path2:before {
  content: "\e99f";
  margin-left: -1em;
}
.icon-Sort .path1:before {
  content: "\e9a0";
}
.icon-Sort .path2:before {
  content: "\e9a1";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Sort1:before {
  content: "\e9a2";
}
.icon-25:before {
  content: "\e9a2";
}
.icon-Sort2:before {
  content: "\e9a3";
}
.icon-32:before {
  content: "\e9a3";
}
.icon-Ticket:before {
  content: "\e9a4";
}
.icon-Wallet .path1:before {
  content: "\e9a5";

  opacity: 0.3;
}
.icon-Wallet .path2:before {
  content: "\e9a6";
  margin-left: -1em;
}
.icon-Wallet1 .path1:before {
  content: "\e9a7";

  opacity: 0.3;
}
.icon-Wallet1 .path2:before {
  content: "\e9a8";
  margin-left: -1em;
}
.icon-Wallet1 .path3:before {
  content: "\e9a9";
  margin-left: -1em;
}
.icon-Wallet2 .path1:before {
  content: "\e9aa";

  opacity: 0.3;
}
.icon-Wallet2 .path2:before {
  content: "\e9ab";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Wallet2 .path3:before {
  content: "\e9ac";
  margin-left: -1em;
}
.icon-Angle-up:before {
  content: "\e9ad";
}
.icon-Angle-double-down .path1:before {
  content: "\e9ae";
}
.icon-Angle-double-down .path2:before {
  content: "\e9af";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Angle-double-left .path1:before {
  content: "\e9b0";
}
.icon-Angle-double-left .path2:before {
  content: "\e9b1";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Angle-double-right .path1:before {
  content: "\e9b2";
}
.icon-Angle-double-right .path2:before {
  content: "\e9b3";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Angle-double-up .path1:before {
  content: "\e9b4";
}
.icon-Angle-double-up .path2:before {
  content: "\e9b5";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Angle-down:before {
  content: "\e9b6";
}
.icon-Angle-left:before {
  content: "\e9b7";
}
.icon-Angle-right:before {
  content: "\e9b8";
}
.icon-Arrow-down .path1:before {
  content: "\e9b9";

  opacity: 0.3;
}
.icon-Arrow-down .path2:before {
  content: "\e9ba";
  margin-left: -1em;
}
.icon-Arrow-from-bottom .path1:before {
  content: "\e9bb";

  opacity: 0.3;
}
.icon-Arrow-from-bottom .path2:before {
  content: "\e9bc";
  margin-left: -1em;
}
.icon-Arrow-from-bottom .path3:before {
  content: "\e9bd";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Arrow-from-left .path1:before {
  content: "\e9be";

  opacity: 0.3;
}
.icon-Arrow-from-left .path2:before {
  content: "\e9bf";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Arrow-from-left .path3:before {
  content: "\e9c0";
  margin-left: -1em;
}
.icon-Arrow-from-right .path1:before {
  content: "\e9c1";

  opacity: 0.3;
}
.icon-Arrow-from-right .path2:before {
  content: "\e9c2";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Arrow-from-right .path3:before {
  content: "\e9c3";
  margin-left: -1em;
}
.icon-Arrow-from-top .path1:before {
  content: "\e9c4";

  opacity: 0.3;
}
.icon-Arrow-from-top .path2:before {
  content: "\e9c5";
  margin-left: -1em;
}
.icon-Arrow-from-top .path3:before {
  content: "\e9c6";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Arrow-left .path1:before {
  content: "\e9c7";

  opacity: 0.3;
}
.icon-Arrow-left .path2:before {
  content: "\e9c8";
  margin-left: -1em;
}
.icon-Arrow-right .path1:before {
  content: "\e9c9";

  opacity: 0.3;
}
.icon-Arrow-right .path2:before {
  content: "\e9ca";
  margin-left: -1em;
}
.icon-Arrows-h .path1:before {
  content: "\e9cb";

  opacity: 0.3;
}
.icon-Arrows-h .path2:before {
  content: "\e9cc";
  margin-left: -1em;
}
.icon-Arrows-h .path3:before {
  content: "\e9cd";
  margin-left: -1em;
}
.icon-Arrows-v .path1:before {
  content: "\e9ce";

  opacity: 0.3;
}
.icon-Arrows-v .path2:before {
  content: "\e9cf";
  margin-left: -1em;
}
.icon-Arrows-v .path3:before {
  content: "\e9d0";
  margin-left: -1em;
}
.icon-Arrow-to-bottom .path1:before {
  content: "\e9d1";

  opacity: 0.3;
}
.icon-Arrow-to-bottom .path2:before {
  content: "\e9d2";
  margin-left: -1em;
}
.icon-Arrow-to-bottom .path3:before {
  content: "\e9d3";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Arrow-to-left .path1:before {
  content: "\e9d4";

  opacity: 0.3;
}
.icon-Arrow-to-left .path2:before {
  content: "\e9d5";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Arrow-to-left .path3:before {
  content: "\e9d6";
  margin-left: -1em;
}
.icon-Arrow-to-right .path1:before {
  content: "\e9d7";

  opacity: 0.3;
}
.icon-Arrow-to-right .path2:before {
  content: "\e9d8";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Arrow-to-right .path3:before {
  content: "\e9d9";
  margin-left: -1em;
}
.icon-Arrow-to-up .path1:before {
  content: "\e9da";

  opacity: 0.3;
}
.icon-Arrow-to-up .path2:before {
  content: "\e9db";
  margin-left: -1em;
}
.icon-Arrow-to-up .path3:before {
  content: "\e9dc";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Arrow-up .path1:before {
  content: "\e9dd";

  opacity: 0.3;
}
.icon-Arrow-up .path2:before {
  content: "\e9de";
  margin-left: -1em;
}
.icon-Check:before {
  content: "\e9df";
}
.icon-Close .path1:before {
  content: "\e9e0";
}
.icon-Close .path2:before {
  content: "\e9e1";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Double-check .path1:before {
  content: "\e9e2";

  opacity: 0.3;
}
.icon-Double-check .path2:before {
  content: "\e9e3";
  margin-left: -1em;
}
.icon-Down-2 .path1:before {
  content: "\e9e4";

  opacity: 0.3;
}
.icon-Down-2 .path2:before {
  content: "\e9e5";
  margin-left: -1em;
}
.icon-Down-left .path1:before {
  content: "\e9e6";

  opacity: 0.3;
}
.icon-Down-left .path2:before {
  content: "\e9e7";
  margin-left: -1em;
}
.icon-Down-right .path1:before {
  content: "\e9e8";

  opacity: 0.3;
}
.icon-Down-right .path2:before {
  content: "\e9e9";
  margin-left: -1em;
}
.icon-Exchange .path1:before {
  content: "\e9ea";

  opacity: 0.3;
}
.icon-Exchange .path2:before {
  content: "\e9eb";
  margin-left: -1em;
}
.icon-Exchange .path3:before {
  content: "\e9ec";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Exchange .path4:before {
  content: "\e9ed";
  margin-left: -1em;
}
.icon-Left-3 .path1:before {
  content: "\e9ee";
}
.icon-Left-3 .path2:before {
  content: "\e9ef";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Left-2 .path1:before {
  content: "\e9f0";

  opacity: 0.3;
}
.icon-Left-2 .path2:before {
  content: "\e9f1";
  margin-left: -1em;
}
.icon-Minus1:before {
  content: "\e9f2";
}
.icon-Plus1 .path1:before {
  content: "\e9f3";
}
.icon-Plus1 .path2:before {
  content: "\e9f4";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Right-3 .path1:before {
  content: "\e9f5";
}
.icon-Right-3 .path2:before {
  content: "\e9f6";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Right-2 .path1:before {
  content: "\e9f7";

  opacity: 0.3;
}
.icon-Right-2 .path2:before {
  content: "\e9f8";
  margin-left: -1em;
}
.icon-Route .path1:before {
  content: "\e9f9";

  opacity: 0.3;
}
.icon-Route .path2:before {
  content: "\e9fa";
  margin-left: -1em;
}
.icon-Route .path3:before {
  content: "\e9fb";
  margin-left: -1em;
}
.icon-Sign-in .path1:before {
  content: "\e9fc";

  opacity: 0.3;
}
.icon-Sign-in .path2:before {
  content: "\e9fd";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Sign-in .path3:before {
  content: "\e9fe";
  margin-left: -1em;
}
.icon-Sign-out .path1:before {
  content: "\e9ff";

  opacity: 0.3;
}
.icon-Sign-out .path2:before {
  content: "\ea00";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Sign-out .path3:before {
  content: "\ea01";
  margin-left: -1em;
}
.icon-Up-2 .path1:before {
  content: "\ea02";

  opacity: 0.3;
}
.icon-Up-2 .path2:before {
  content: "\ea03";
  margin-left: -1em;
}
.icon-Up-down .path1:before {
  content: "\ea04";

  opacity: 0.3;
}
.icon-Up-down .path2:before {
  content: "\ea05";
  margin-left: -1em;
}
.icon-Up-down .path3:before {
  content: "\ea06";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Up-down .path4:before {
  content: "\ea07";
  margin-left: -1em;
}
.icon-Up-left .path1:before {
  content: "\ea08";

  opacity: 0.3;
}
.icon-Up-left .path2:before {
  content: "\ea09";
  margin-left: -1em;
}
.icon-Up-right .path1:before {
  content: "\ea0a";

  opacity: 0.3;
}
.icon-Up-right .path2:before {
  content: "\ea0b";
  margin-left: -1em;
}
.icon-Waiting:before {
  content: "\ea0c";
}
.icon-Add-music .path1:before {
  content: "\ea0d";
}
.icon-Add-music .path2:before {
  content: "\ea0e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Airplay .path1:before {
  content: "\ea0f";

  opacity: 0.3;
}
.icon-Airplay .path2:before {
  content: "\ea10";
  margin-left: -1em;
}
.icon-Airplay-video .path1:before {
  content: "\ea11";
}
.icon-Airplay-video .path2:before {
  content: "\ea12";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Back .path1:before {
  content: "\ea13";
}
.icon-Back .path2:before {
  content: "\ea14";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Backward .path1:before {
  content: "\ea15";

  opacity: 0.3;
}
.icon-Backward .path2:before {
  content: "\ea16";
  margin-left: -1em;
}
.icon-CD .path1:before {
  content: "\ea17";
}
.icon-CD .path2:before {
  content: "\ea18";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-DVD .path1:before {
  content: "\ea19";

  opacity: 0.3;
}
.icon-DVD .path2:before {
  content: "\ea1a";
  margin-left: -1em;
}
.icon-Eject .path1:before {
  content: "\ea1b";
}
.icon-Eject .path2:before {
  content: "\ea1c";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Equalizer .path1:before {
  content: "\ea1d";

  opacity: 0.3;
}
.icon-Equalizer .path2:before {
  content: "\ea1e";
  margin-left: -1em;
}
.icon-Equalizer .path3:before {
  content: "\ea1f";
  margin-left: -1em;
}
.icon-Equalizer .path4:before {
  content: "\ea20";
  margin-left: -1em;
}
.icon-Forward .path1:before {
  content: "\ea21";

  opacity: 0.3;
}
.icon-Forward .path2:before {
  content: "\ea22";
  margin-left: -1em;
}
.icon-Media-library .path1:before {
  content: "\ea23";

  opacity: 0.3;
}
.icon-Media-library .path2:before {
  content: "\ea24";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Media-library .path3:before {
  content: "\ea25";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Media-library .path4:before {
  content: "\ea26";
  margin-left: -1em;
}
.icon-Media-library1 .path1:before {
  content: "\ea27";

  opacity: 0.3;
}
.icon-Media-library1 .path2:before {
  content: "\ea28";
  margin-left: -1em;
}
.icon-Media-library2 .path1:before {
  content: "\ea29";

  opacity: 0.09;
}
.icon-Media-library2 .path2:before {
  content: "\ea2a";
  margin-left: -1em;
}
.icon-Movie-Lane .path1:before {
  content: "\ea2b";

  opacity: 0.3;
}
.icon-Movie-Lane .path2:before {
  content: "\ea2c";
  margin-left: -1em;
}
.icon-Movie-lane .path1:before {
  content: "\ea2d";

  opacity: 0.3;
}
.icon-Movie-lane .path2:before {
  content: "\ea2e";
  margin-left: -1em;
}
.icon-Music1:before {
  content: "\ea2f";
}
.icon-Music-cloud .path1:before {
  content: "\ea30";

  opacity: 0.3;
}
.icon-Music-cloud .path2:before {
  content: "\ea31";
  margin-left: -1em;
}
.icon-Music-note:before {
  content: "\ea32";
}
.icon-Mute .path1:before {
  content: "\ea33";

  opacity: 0.3;
}
.icon-Mute .path2:before {
  content: "\ea34";
  margin-left: -1em;
}
.icon-Next .path1:before {
  content: "\ea35";
}
.icon-Next .path2:before {
  content: "\ea36";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Pause:before {
  content: "\ea37";
}
.icon-Play:before {
  content: "\ea38";
}
.icon-Playlist .path1:before {
  content: "\ea39";
}
.icon-Playlist .path2:before {
  content: "\ea3a";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Playlist1 .path1:before {
  content: "\ea3b";

  opacity: 0.3;
}
.icon-Playlist1 .path2:before {
  content: "\ea3c";
  margin-left: -1em;
}
.icon-Rec:before {
  content: "\ea3d";
}
.icon-Repeat .path1:before {
  content: "\ea3e";
}
.icon-Repeat .path2:before {
  content: "\ea3f";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Repeat-one .path1:before {
  content: "\ea40";

  opacity: 0.3;
}
.icon-Repeat-one .path2:before {
  content: "\ea41";
  margin-left: -1em;
}
.icon-Shuffle .path1:before {
  content: "\ea42";

  opacity: 0.3;
}
.icon-Shuffle .path2:before {
  content: "\ea43";
  margin-left: -1em;
}
.icon-Volume-down .path1:before {
  content: "\ea44";

  opacity: 0.3;
}
.icon-Volume-down .path2:before {
  content: "\ea45";
  margin-left: -1em;
}
.icon-Volume-full .path1:before {
  content: "\ea46";

  opacity: 0.3;
}
.icon-Volume-full .path2:before {
  content: "\ea47";
  margin-left: -1em;
}
.icon-Volume-half .path1:before {
  content: "\ea48";

  opacity: 0.3;
}
.icon-Volume-half .path2:before {
  content: "\ea49";
  margin-left: -1em;
}
.icon-Volume-up .path1:before {
  content: "\ea4a";

  opacity: 0.3;
}
.icon-Volume-up .path2:before {
  content: "\ea4b";
  margin-left: -1em;
}
.icon-Vynil .path1:before {
  content: "\ea4c";
}
.icon-Vynil .path2:before {
  content: "\ea4d";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Youtube .path1:before {
  content: "\ea4e";

  opacity: 0.3;
}
.icon-Youtube .path2:before {
  content: "\ea4f";
  margin-left: -1em;
}
.icon-Compass1:before {
  content: "\ea50";
}
.icon-Direction1:before {
  content: "\ea51";
}
.icon-136:before {
  content: "\ea51";
}
.icon-Direction:before {
  content: "\ea52";
}
.icon-228:before {
  content: "\ea52";
}
.icon-Location-arrow:before {
  content: "\ea53";
}
.icon-Marker:before {
  content: "\ea54";
}
.icon-128:before {
  content: "\ea54";
}
.icon-Marker1:before {
  content: "\ea55";
}
.icon-229:before {
  content: "\ea55";
}
.icon-Position1 .path1:before {
  content: "\ea56";

  opacity: 0.3;
}
.icon-Position1 .path2:before {
  content: "\ea57";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Position1 .path3:before {
  content: "\ea58";
  margin-left: -1em;
}
.icon-Layout-3d .path1:before {
  content: "\ea59";
}
.icon-Layout-3d .path2:before {
  content: "\ea5a";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Layout-4-blocks .path1:before {
  content: "\ea5b";
}
.icon-Layout-4-blocks .path2:before {
  content: "\ea5c";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Layout-arrange .path1:before {
  content: "\ea5d";
}
.icon-Layout-arrange .path2:before {
  content: "\ea5e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Layout-grid .path1:before {
  content: "\ea5f";

  opacity: 0.3;
}
.icon-Layout-grid .path2:before {
  content: "\ea60";
  margin-left: -1em;
}
.icon-Layout-horizontal .path1:before {
  content: "\ea61";

  opacity: 0.3;
}
.icon-Layout-horizontal .path2:before {
  content: "\ea62";
  margin-left: -1em;
}
.icon-Layout-left-panel-1 .path1:before {
  content: "\ea63";
}
.icon-Layout-left-panel-1 .path2:before {
  content: "\ea64";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Layout-left-panel-2 .path1:before {
  content: "\ea65";
}
.icon-Layout-left-panel-2 .path2:before {
  content: "\ea66";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Layout-right-panel-1 .path1:before {
  content: "\ea67";
}
.icon-Layout-right-panel-1 .path2:before {
  content: "\ea68";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Layout-right-panel-2 .path1:before {
  content: "\ea69";
}
.icon-Layout-right-panel-2 .path2:before {
  content: "\ea6a";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Layout-top-panel-1 .path1:before {
  content: "\ea6b";
}
.icon-Layout-top-panel-1 .path2:before {
  content: "\ea6c";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Layout-top-panel-2 .path1:before {
  content: "\ea6d";
}
.icon-Layout-top-panel-2 .path2:before {
  content: "\ea6e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Layout-top-panel-3 .path1:before {
  content: "\ea6f";
}
.icon-Layout-top-panel-3 .path2:before {
  content: "\ea70";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Layout-top-panel-4 .path1:before {
  content: "\ea71";
}
.icon-Layout-top-panel-4 .path2:before {
  content: "\ea72";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Layout-top-panel-5 .path1:before {
  content: "\ea73";
}
.icon-Layout-top-panel-5 .path2:before {
  content: "\ea74";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Layout-top-panel-6 .path1:before {
  content: "\ea75";
}
.icon-Layout-top-panel-6 .path2:before {
  content: "\ea76";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Layout-vertical .path1:before {
  content: "\ea77";
}
.icon-Layout-vertical .path2:before {
  content: "\ea78";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Air-ballon .path1:before {
  content: "\ea79";
}
.icon-Air-ballon .path2:before {
  content: "\ea7a";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Alarm-clock .path1:before {
  content: "\ea7b";
}
.icon-Alarm-clock .path2:before {
  content: "\ea7c";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Alarm-clock .path3:before {
  content: "\ea7d";
  margin-left: -1em;
}
.icon-Armchair .path1:before {
  content: "\ea7e";

  opacity: 0.3;
}
.icon-Armchair .path2:before {
  content: "\ea7f";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Armchair .path3:before {
  content: "\ea80";
  margin-left: -1em;
}
.icon-Bag-chair:before {
  content: "\ea81";
}
.icon-Bath .path1:before {
  content: "\ea82";

  opacity: 0.3;
}
.icon-Bath .path2:before {
  content: "\ea83";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Bath .path3:before {
  content: "\ea84";
  margin-left: -1em;
}
.icon-Bed .path1:before {
  content: "\ea85";

  opacity: 0.3;
}
.icon-Bed .path2:before {
  content: "\ea86";
  margin-left: -1em;
}
.icon-Bed .path3:before {
  content: "\ea87";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Book:before {
  content: "\ea88";
}
.icon-Book-open .path1:before {
  content: "\ea89";
}
.icon-Book-open .path2:before {
  content: "\ea8a";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Box .path1:before {
  content: "\ea8b";
}
.icon-Box .path2:before {
  content: "\ea8c";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Broom .path1:before {
  content: "\ea8d";

  opacity: 0.3;
}
.icon-Broom .path2:before {
  content: "\ea8e";
  margin-left: -1em;
}
.icon-Building .path1:before {
  content: "\ea8f";
}
.icon-Building .path2:before {
  content: "\ea90";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Building .path3:before {
  content: "\ea91";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Bulb .path1:before {
  content: "\ea92";

  opacity: 0.3;
}
.icon-Bulb .path2:before {
  content: "\ea93";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Bulb .path3:before {
  content: "\ea94";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Bulb .path4:before {
  content: "\ea95";
  margin-left: -1em;
}
.icon-Bulb1 .path1:before {
  content: "\ea96";

  opacity: 0.3;
}
.icon-Bulb1 .path2:before {
  content: "\ea97";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Bulb1 .path3:before {
  content: "\ea98";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Bulb1 .path4:before {
  content: "\ea99";
  margin-left: -1em;
}
.icon-Chair .path1:before {
  content: "\ea9a";
}
.icon-Chair .path2:before {
  content: "\ea9b";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Chair1 .path1:before {
  content: "\ea9c";

  opacity: 0.3;
}
.icon-Chair1 .path2:before {
  content: "\ea9d";
  margin-left: -1em;
}
.icon-Clock .path1:before {
  content: "\ea9e";

  opacity: 0.3;
}
.icon-Clock .path2:before {
  content: "\ea9f";
  margin-left: -1em;
}
.icon-Commode .path1:before {
  content: "\eaa0";

  opacity: 0.3;
}
.icon-Commode .path2:before {
  content: "\eaa1";
  margin-left: -1em;
}
.icon-Commode1 .path1:before {
  content: "\eaa2";

  opacity: 0.3;
}
.icon-Commode1 .path2:before {
  content: "\eaa3";
  margin-left: -1em;
}
.icon-Couch .path1:before {
  content: "\eaa4";

  opacity: 0.3;
}
.icon-Couch .path2:before {
  content: "\eaa5";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Couch .path3:before {
  content: "\eaa6";
  margin-left: -1em;
}
.icon-Cupboard .path1:before {
  content: "\eaa7";

  opacity: 0.3;
}
.icon-Cupboard .path2:before {
  content: "\eaa8";
  margin-left: -1em;
}
.icon-Curtains .path1:before {
  content: "\eaa9";

  opacity: 0.3;
}
.icon-Curtains .path2:before {
  content: "\eaaa";
  margin-left: -1em;
}
.icon-Deer .path1:before {
  content: "\eaab";

  opacity: 0.3;
}
.icon-Deer .path2:before {
  content: "\eaac";
  margin-left: -1em;
}
.icon-Door-open .path1:before {
  content: "\eaad";

  opacity: 0.3;
}
.icon-Door-open .path2:before {
  content: "\eaae";
  margin-left: -1em;
}
.icon-Earth:before {
  content: "\eaaf";
}
.icon-Fireplace .path1:before {
  content: "\eab0";

  opacity: 0.3;
}
.icon-Fireplace .path2:before {
  content: "\eab1";
  margin-left: -1em;
}
.icon-Flashlight .path1:before {
  content: "\eab2";
}
.icon-Flashlight .path2:before {
  content: "\eab3";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Flower .path1:before {
  content: "\eab4";

  opacity: 0.3;
}
.icon-Flower .path2:before {
  content: "\eab5";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Flower .path3:before {
  content: "\eab6";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Flower .path4:before {
  content: "\eab7";
  margin-left: -1em;
}
.icon-Flower1:before {
  content: "\eab8";
}
.icon-239:before {
  content: "\eab8";
}
.icon-Flower2:before {
  content: "\eab9";
}
.icon-38:before {
  content: "\eab9";
}
.icon-Globe .path1:before {
  content: "\eaba";
}
.icon-Globe .path2:before {
  content: "\eabb";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Home:before {
  content: "\eabc";
}
.icon-Home-heart:before {
  content: "\eabd";
}
.icon-Key .path1:before {
  content: "\eabe";

  opacity: 0.3;
}
.icon-Key .path2:before {
  content: "\eabf";
  margin-left: -1em;
}
.icon-Ladder .path1:before {
  content: "\eac0";

  opacity: 0.3;
}
.icon-Ladder .path2:before {
  content: "\eac1";
  margin-left: -1em;
}
.icon-Lamp .path1:before {
  content: "\eac2";
}
.icon-Lamp .path2:before {
  content: "\eac3";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Lamp .path3:before {
  content: "\eac4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Lamp .path4:before {
  content: "\eac5";
  margin-left: -1em;
}
.icon-Lamp1 .path1:before {
  content: "\eac6";

  opacity: 0.3;
}
.icon-Lamp1 .path2:before {
  content: "\eac7";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Lamp1 .path3:before {
  content: "\eac8";
  margin-left: -1em;
}
.icon-Library .path1:before {
  content: "\eac9";
}
.icon-Library .path2:before {
  content: "\eaca";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Mailbox .path1:before {
  content: "\eacb";
}
.icon-Mailbox .path2:before {
  content: "\eacc";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Mirror .path1:before {
  content: "\eacd";
}
.icon-Mirror .path2:before {
  content: "\eace";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Picture .path1:before {
  content: "\eacf";

  opacity: 0.3;
}
.icon-Picture .path2:before {
  content: "\ead0";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Picture .path3:before {
  content: "\ead1";
  margin-left: -1em;
}
.icon-Picture .path4:before {
  content: "\ead2";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Ruller:before {
  content: "\ead3";
}
.icon-Stairs:before {
  content: "\ead4";
}
.icon-Timer .path1:before {
  content: "\ead5";

  opacity: 0.3;
}
.icon-Timer .path2:before {
  content: "\ead6";
  margin-left: -1em;
}
.icon-Timer .path3:before {
  content: "\ead7";
  margin-left: -1em;
}
.icon-Timer .path4:before {
  content: "\ead8";
  margin-left: -1em;
}
.icon-Toilet .path1:before {
  content: "\ead9";

  opacity: 0.3;
}
.icon-Toilet .path2:before {
  content: "\eada";
  margin-left: -1em;
}
.icon-Toilet .path3:before {
  content: "\eadb";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Towel:before {
  content: "\eadc";
}
.icon-Trash1 .path1:before {
  content: "\eadd";
}
.icon-Trash1 .path2:before {
  content: "\eade";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Water-mixer .path1:before {
  content: "\eadf";

  opacity: 0.3;
}
.icon-Water-mixer .path2:before {
  content: "\eae0";
  margin-left: -1em;
}
.icon-Water-mixer .path3:before {
  content: "\eae1";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Weight .path1:before {
  content: "\eae2";
}
.icon-Weight .path2:before {
  content: "\eae3";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Weight1 .path1:before {
  content: "\eae4";

  opacity: 0.3;
}
.icon-Weight1 .path2:before {
  content: "\eae5";
  margin-left: -1em;
}
.icon-Wood .path1:before {
  content: "\eae6";

  opacity: 0.3;
}
.icon-Wood .path2:before {
  content: "\eae7";
  margin-left: -1em;
}
.icon-Wood1 .path1:before {
  content: "\eae8";

  opacity: 0.3;
}
.icon-Wood1 .path2:before {
  content: "\eae9";
  margin-left: -1em;
}
.icon-Wood-horse:before {
  content: "\eaea";
}
.icon-Attachment .path1:before {
  content: "\eaeb";

  opacity: 0.3;
}
.icon-Attachment .path2:before {
  content: "\eaec";
  margin-left: -1em;
}
.icon-Attachment1 .path1:before {
  content: "\eaed";

  opacity: 0.3;
}
.icon-Attachment1 .path2:before {
  content: "\eaee";
  margin-left: -1em;
}
.icon-Attachment1 .path3:before {
  content: "\eaef";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Attachment1 .path4:before {
  content: "\eaf0";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Binocular:before {
  content: "\eaf1";
}
.icon-Bookmark:before {
  content: "\eaf2";
}
.icon-Clip:before {
  content: "\eaf3";
}
.icon-Clipboard .path1:before {
  content: "\eaf4";

  opacity: 0.3;
}
.icon-Clipboard .path2:before {
  content: "\eaf5";
  margin-left: -1em;
}
.icon-Clipboard .path3:before {
  content: "\eaf6";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Clipboard .path4:before {
  content: "\eaf7";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Cursor:before {
  content: "\eaf8";
}
.icon-Dislike .path1:before {
  content: "\eaf9";
}
.icon-Dislike .path2:before {
  content: "\eafa";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Duplicate .path1:before {
  content: "\eafb";

  opacity: 0.3;
}
.icon-Duplicate .path2:before {
  content: "\eafc";
  margin-left: -1em;
}
.icon-Edit1:before {
  content: "\eafd";
}
.icon-Expand-arrows .path1:before {
  content: "\eafe";

  opacity: 0.3;
}
.icon-Expand-arrows .path2:before {
  content: "\eaff";
  margin-left: -1em;
}
.icon-Fire:before {
  content: "\eb00";
}
.icon-Folder1:before {
  content: "\eb01";
}
.icon-Half-heart .path1:before {
  content: "\eb02";

  opacity: 0.3;
}
.icon-Half-heart .path2:before {
  content: "\eb03";
  margin-left: -1em;
}
.icon-Half-star .path1:before {
  content: "\eb04";

  opacity: 0.3;
}
.icon-Half-star .path2:before {
  content: "\eb05";
  margin-left: -1em;
}
.icon-Heart:before {
  content: "\eb06";
}
.icon-Hidden .path1:before {
  content: "\eb07";
}
.icon-Hidden .path2:before {
  content: "\eb08";
  margin-left: -1em;
}
.icon-Hidden .path3:before {
  content: "\eb09";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Like .path1:before {
  content: "\eb0a";
}
.icon-Like .path2:before {
  content: "\eb0b";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Lock:before {
  content: "\eb0c";
}
.icon-Notification .path1:before {
  content: "\eb0d";
}
.icon-Notification .path2:before {
  content: "\eb0e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Notifications .path1:before {
  content: "\eb0f";
}
.icon-Notifications .path2:before {
  content: "\eb10";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Other:before {
  content: "\eb11";
}
.icon-133:before {
  content: "\eb11";
}
.icon-Other1:before {
  content: "\eb12";
}
.icon-234:before {
  content: "\eb12";
}
.icon-Sad .path1:before {
  content: "\eb13";

  opacity: 0.3;
}
.icon-Sad .path2:before {
  content: "\eb14";
  margin-left: -1em;
}
.icon-Save .path1:before {
  content: "\eb15";
}
.icon-Save .path2:before {
  content: "\eb16";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Scale .path1:before {
  content: "\eb17";
}
.icon-Scale .path2:before {
  content: "\eb18";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Scissors .path1:before {
  content: "\eb19";

  opacity: 0.3;
}
.icon-Scissors .path2:before {
  content: "\eb1a";
  margin-left: -1em;
}
.icon-Search .path1:before {
  content: "\eb1b";

  opacity: 0.3;
}
.icon-Search .path2:before {
  content: "\eb1c";
  margin-left: -1em;
}
.icon-Settings2 .path1:before {
  content: "\eb1d";

  opacity: 0.3;
}
.icon-Settings2 .path2:before {
  content: "\eb1e";
  margin-left: -1em;
}
.icon-Settings-1 .path1:before {
  content: "\eb1f";
}
.icon-Settings-1 .path2:before {
  content: "\eb20";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Settings-2:before {
  content: "\eb21";
}
.icon-Shield-check .path1:before {
  content: "\eb22";

  opacity: 0.3;
}
.icon-Shield-check .path2:before {
  content: "\eb23";
  margin-left: -1em;
}
.icon-Shield-disabled .path1:before {
  content: "\eb24";

  opacity: 0.3;
}
.icon-Shield-disabled .path2:before {
  content: "\eb25";
  margin-left: -1em;
}
.icon-Shield-protected .path1:before {
  content: "\eb26";

  opacity: 0.3;
}
.icon-Shield-protected .path2:before {
  content: "\eb27";
  margin-left: -1em;
}
.icon-Size .path1:before {
  content: "\eb28";
}
.icon-Size .path2:before {
  content: "\eb29";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Smile .path1:before {
  content: "\eb2a";

  opacity: 0.3;
}
.icon-Smile .path2:before {
  content: "\eb2b";
  margin-left: -1em;
}
.icon-Star:before {
  content: "\eb2c";
}
.icon-Thunder1:before {
  content: "\eb2d";
}
.icon-Thunder-move .path1:before {
  content: "\eb2e";
}
.icon-Thunder-move .path2:before {
  content: "\eb2f";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Trash .path1:before {
  content: "\eb30";
}
.icon-Trash .path2:before {
  content: "\eb31";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Unlock:before {
  content: "\eb32";
}
.icon-Update:before {
  content: "\eb33";
}
.icon-User .path1:before {
  content: "\eb34";

  opacity: 0.3;
}
.icon-User .path2:before {
  content: "\eb35";
  margin-left: -1em;
}
.icon-Visible:before {
  content: "\eb36";
}
.icon-Beer .path1:before {
  content: "\eb37";

  opacity: 0.3;
}
.icon-Beer .path2:before {
  content: "\eb38";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Beer .path3:before {
  content: "\eb39";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Beer .path4:before {
  content: "\eb3a";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Beer .path5:before {
  content: "\eb3b";
  margin-left: -1em;
}
.icon-Bottle .path1:before {
  content: "\eb3c";
}
.icon-Bottle .path2:before {
  content: "\eb3d";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Bottle1 .path1:before {
  content: "\eb3e";
}
.icon-Bottle1 .path2:before {
  content: "\eb3f";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Bread .path1:before {
  content: "\eb40";

  opacity: 0.3;
}
.icon-Bread .path2:before {
  content: "\eb41";
  margin-left: -1em;
}
.icon-Bucket1 .path1:before {
  content: "\eb42";

  opacity: 0.3;
}
.icon-Bucket1 .path2:before {
  content: "\eb43";
  margin-left: -1em;
}
.icon-Burger .path1:before {
  content: "\eb44";
}
.icon-Burger .path2:before {
  content: "\eb45";
  margin-left: -1em;
}
.icon-Burger .path3:before {
  content: "\eb46";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Cake .path1:before {
  content: "\eb47";
}
.icon-Cake .path2:before {
  content: "\eb48";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Cake .path3:before {
  content: "\eb49";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Carrot .path1:before {
  content: "\eb4a";

  opacity: 0.3;
}
.icon-Carrot .path2:before {
  content: "\eb4b";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Carrot .path3:before {
  content: "\eb4c";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Carrot .path4:before {
  content: "\eb4d";
  margin-left: -1em;
}
.icon-Cheese .path1:before {
  content: "\eb4e";
}
.icon-Cheese .path2:before {
  content: "\eb4f";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Chicken .path1:before {
  content: "\eb50";
}
.icon-Chicken .path2:before {
  content: "\eb51";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Coffee .path1:before {
  content: "\eb52";
}
.icon-Coffee .path2:before {
  content: "\eb53";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Coffee .path3:before {
  content: "\eb54";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Coffee .path4:before {
  content: "\eb55";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Coffee .path5:before {
  content: "\eb56";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Coffee1 .path1:before {
  content: "\eb57";

  opacity: 0.3;
}
.icon-Coffee1 .path2:before {
  content: "\eb58";
  margin-left: -1em;
}
.icon-Coffee1 .path3:before {
  content: "\eb59";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Cookie:before {
  content: "\eb5a";
}
.icon-Dinner1 .path1:before {
  content: "\eb5b";

  opacity: 0.3;
}
.icon-Dinner1 .path2:before {
  content: "\eb5c";
  margin-left: -1em;
}
.icon-Fish .path1:before {
  content: "\eb5d";

  opacity: 0.3;
}
.icon-Fish .path2:before {
  content: "\eb5e";
  margin-left: -1em;
}
.icon-French-Bread:before {
  content: "\eb5f";
}
.icon-Glass-martini .path1:before {
  content: "\eb60";

  opacity: 0.3;
}
.icon-Glass-martini .path2:before {
  content: "\eb61";
  margin-left: -1em;
}
.icon-Ice-cream1 .path1:before {
  content: "\eb62";

  opacity: 0.3;
}
.icon-Ice-cream1 .path2:before {
  content: "\eb63";
  margin-left: -1em;
}
.icon-Ice-cream .path1:before {
  content: "\eb64";
}
.icon-Ice-cream .path2:before {
  content: "\eb65";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Miso-soup .path1:before {
  content: "\eb66";
}
.icon-Miso-soup .path2:before {
  content: "\eb67";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Orange .path1:before {
  content: "\eb68";
}
.icon-Orange .path2:before {
  content: "\eb69";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Pizza:before {
  content: "\eb6a";
}
.icon-Sushi .path1:before {
  content: "\eb6b";
}
.icon-Sushi .path2:before {
  content: "\eb6c";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Two-bottles .path1:before {
  content: "\eb6d";
}
.icon-Two-bottles .path2:before {
  content: "\eb6e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Wine .path1:before {
  content: "\eb6f";

  opacity: 0.3;
}
.icon-Wine .path2:before {
  content: "\eb70";
  margin-left: -1em;
}
.icon-Cloud-download .path1:before {
  content: "\eb71";

  opacity: 0.3;
}
.icon-Cloud-download .path2:before {
  content: "\eb72";
  margin-left: -1em;
}
.icon-Cloud-upload .path1:before {
  content: "\eb73";

  opacity: 0.3;
}
.icon-Cloud-upload .path2:before {
  content: "\eb74";
  margin-left: -1em;
}
.icon-Compilation .path1:before {
  content: "\eb75";

  opacity: 0.3;
}
.icon-Compilation .path2:before {
  content: "\eb76";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Compilation .path3:before {
  content: "\eb77";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Compilation .path4:before {
  content: "\eb78";
  margin-left: -1em;
}
.icon-Compilation .path5:before {
  content: "\eb79";
  margin-left: -1em;
}
.icon-Compiled-file .path1:before {
  content: "\eb7a";

  opacity: 0.3;
}
.icon-Compiled-file .path2:before {
  content: "\eb7b";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Compiled-file .path3:before {
  content: "\eb7c";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Compiled-file .path4:before {
  content: "\eb7d";
  margin-left: -1em;
}
.icon-Compiled-file .path5:before {
  content: "\eb7e";
  margin-left: -1em;
}
.icon-Deleted-file .path1:before {
  content: "\eb7f";

  opacity: 0.3;
}
.icon-Deleted-file .path2:before {
  content: "\eb80";
  margin-left: -1em;
}
.icon-Deleted-folder .path1:before {
  content: "\eb81";

  opacity: 0.3;
}
.icon-Deleted-folder .path2:before {
  content: "\eb82";
  margin-left: -1em;
}
.icon-Download .path1:before {
  content: "\eb83";

  opacity: 0.3;
}
.icon-Download .path2:before {
  content: "\eb84";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Download .path3:before {
  content: "\eb85";
  margin-left: -1em;
}
.icon-Downloaded-file .path1:before {
  content: "\eb86";

  opacity: 0.3;
}
.icon-Downloaded-file .path2:before {
  content: "\eb87";
  margin-left: -1em;
}
.icon-Downloads-folder .path1:before {
  content: "\eb88";

  opacity: 0.3;
}
.icon-Downloads-folder .path2:before {
  content: "\eb89";
  margin-left: -1em;
}
.icon-Export .path1:before {
  content: "\eb8a";

  opacity: 0.3;
}
.icon-Export .path2:before {
  content: "\eb8b";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Export .path3:before {
  content: "\eb8c";
  margin-left: -1em;
}
.icon-File .path1:before {
  content: "\eb8d";

  opacity: 0.3;
}
.icon-File .path2:before {
  content: "\eb8e";
  margin-left: -1em;
}
.icon-File .path3:before {
  content: "\eb8f";
  margin-left: -1em;
}
.icon-File-cloud .path1:before {
  content: "\eb90";

  opacity: 0.3;
}
.icon-File-cloud .path2:before {
  content: "\eb91";
  margin-left: -1em;
}
.icon-File-done .path1:before {
  content: "\eb92";

  opacity: 0.3;
}
.icon-File-done .path2:before {
  content: "\eb93";
  margin-left: -1em;
}
.icon-File-minus .path1:before {
  content: "\eb94";

  opacity: 0.3;
}
.icon-File-minus .path2:before {
  content: "\eb95";
  margin-left: -1em;
}
.icon-File-plus .path1:before {
  content: "\eb96";

  opacity: 0.3;
}
.icon-File-plus .path2:before {
  content: "\eb97";
  margin-left: -1em;
}
.icon-Folder:before {
  content: "\eb98";
}
.icon-Folder-check .path1:before {
  content: "\eb99";

  opacity: 0.3;
}
.icon-Folder-check .path2:before {
  content: "\eb9a";
  margin-left: -1em;
}
.icon-Folder-cloud .path1:before {
  content: "\eb9b";

  opacity: 0.3;
}
.icon-Folder-cloud .path2:before {
  content: "\eb9c";
  margin-left: -1em;
}
.icon-Folder-error .path1:before {
  content: "\eb9d";
}
.icon-Folder-error .path2:before {
  content: "\eb9e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Folder-heart:before {
  content: "\eb9f";
}
.icon-Folder-minus .path1:before {
  content: "\eba0";

  opacity: 0.3;
}
.icon-Folder-minus .path2:before {
  content: "\eba1";
  margin-left: -1em;
}
.icon-Folder-plus .path1:before {
  content: "\eba2";

  opacity: 0.3;
}
.icon-Folder-plus .path2:before {
  content: "\eba3";
  margin-left: -1em;
}
.icon-Folder-solid:before {
  content: "\eba4";
}
.icon-Folder-star:before {
  content: "\eba5";
}
.icon-Folder-thunder:before {
  content: "\eba6";
}
.icon-Group-folders .path1:before {
  content: "\eba7";

  opacity: 0.3;
}
.icon-Group-folders .path2:before {
  content: "\eba8";
  margin-left: -1em;
}
.icon-Import .path1:before {
  content: "\eba9";

  opacity: 0.3;
}
.icon-Import .path2:before {
  content: "\ebaa";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Import .path3:before {
  content: "\ebab";
  margin-left: -1em;
}
.icon-Locked-folder .path1:before {
  content: "\ebac";

  opacity: 0.3;
}
.icon-Locked-folder .path2:before {
  content: "\ebad";
  margin-left: -1em;
}
.icon-Media .path1:before {
  content: "\ebae";

  opacity: 0.3;
}
.icon-Media .path2:before {
  content: "\ebaf";
  margin-left: -1em;
}
.icon-Media-folder .path1:before {
  content: "\ebb0";

  opacity: 0.3;
}
.icon-Media-folder .path2:before {
  content: "\ebb1";
  margin-left: -1em;
}
.icon-Music .path1:before {
  content: "\ebb2";

  opacity: 0.3;
}
.icon-Music .path2:before {
  content: "\ebb3";
  margin-left: -1em;
}
.icon-Pictures .path1:before {
  content: "\ebb4";

  opacity: 0.3;
}
.icon-Pictures .path2:before {
  content: "\ebb5";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Pictures .path3:before {
  content: "\ebb6";
  margin-left: -1em;
}
.icon-Pictures .path4:before {
  content: "\ebb7";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Pictures1:before {
  content: "\ebb8";
}
.icon-221:before {
  content: "\ebb8";
}
.icon-Protected-file .path1:before {
  content: "\ebb9";

  opacity: 0.3;
}
.icon-Protected-file .path2:before {
  content: "\ebba";
  margin-left: -1em;
}
.icon-Selected-file .path1:before {
  content: "\ebbb";

  opacity: 0.3;
}
.icon-Selected-file .path2:before {
  content: "\ebbc";
  margin-left: -1em;
}
.icon-Share1 .path1:before {
  content: "\ebbd";
}
.icon-Share1 .path2:before {
  content: "\ebbe";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Share1 .path3:before {
  content: "\ebbf";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Share1 .path4:before {
  content: "\ebc0";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Upload .path1:before {
  content: "\ebc1";

  opacity: 0.3;
}
.icon-Upload .path2:before {
  content: "\ebc2";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Upload .path3:before {
  content: "\ebc3";
  margin-left: -1em;
}
.icon-Uploaded-file .path1:before {
  content: "\ebc4";

  opacity: 0.3;
}
.icon-Uploaded-file .path2:before {
  content: "\ebc5";
  margin-left: -1em;
}
.icon-Upload-folder .path1:before {
  content: "\ebc6";

  opacity: 0.3;
}
.icon-Upload-folder .path2:before {
  content: "\ebc7";
  margin-left: -1em;
}
.icon-User-folder:before {
  content: "\ebc8";
}
.icon-Air-conditioning .path1:before {
  content: "\ebc9";
}
.icon-Air-conditioning .path2:before {
  content: "\ebca";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-air-dryer .path1:before {
  content: "\ebcb";

  opacity: 0.3;
}
.icon-air-dryer .path2:before {
  content: "\ebcc";
  margin-left: -1em;
}
.icon-Blender .path1:before {
  content: "\ebcd";
}
.icon-Blender .path2:before {
  content: "\ebce";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Fan:before {
  content: "\ebcf";
}
.icon-Fridge:before {
  content: "\ebd0";
}
.icon-Gas-stove .path1:before {
  content: "\ebd1";
}
.icon-Gas-stove .path2:before {
  content: "\ebd2";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Highvoltage:before {
  content: "\ebd3";
}
.icon-Iron .path1:before {
  content: "\ebd4";

  opacity: 0.3;
}
.icon-Iron .path2:before {
  content: "\ebd5";
  margin-left: -1em;
}
.icon-Kettle .path1:before {
  content: "\ebd6";
}
.icon-Kettle .path2:before {
  content: "\ebd7";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Mixer .path1:before {
  content: "\ebd8";
}
.icon-Mixer .path2:before {
  content: "\ebd9";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Outlet .path1:before {
  content: "\ebda";

  opacity: 0.3;
}
.icon-Outlet .path2:before {
  content: "\ebdb";
  margin-left: -1em;
}
.icon-Range-hood .path1:before {
  content: "\ebdc";
}
.icon-Range-hood .path2:before {
  content: "\ebdd";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Shutdown .path1:before {
  content: "\ebde";
}
.icon-Shutdown .path2:before {
  content: "\ebdf";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Socket-eu:before {
  content: "\ebe0";
}
.icon-Socket-us:before {
  content: "\ebe1";
}
.icon-Washer .path1:before {
  content: "\ebe2";
}
.icon-Washer .path2:before {
  content: "\ebe3";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Airpods .path1:before {
  content: "\ebe4";

  opacity: 0.3;
}
.icon-Airpods .path2:before {
  content: "\ebe5";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Airpods .path3:before {
  content: "\ebe6";
  margin-left: -1em;
}
.icon-Airpods .path4:before {
  content: "\ebe7";
  margin-left: -1em;
}
.icon-Android .path1:before {
  content: "\ebe8";
}
.icon-Android .path2:before {
  content: "\ebe9";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Apple-Watch .path1:before {
  content: "\ebea";

  opacity: 0.3;
}
.icon-Apple-Watch .path2:before {
  content: "\ebeb";
  margin-left: -1em;
}
.icon-Battery-charging .path1:before {
  content: "\ebec";
}
.icon-Battery-charging .path2:before {
  content: "\ebed";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Battery-charging .path3:before {
  content: "\ebee";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Battery-empty .path1:before {
  content: "\ebef";
}
.icon-Battery-empty .path2:before {
  content: "\ebf0";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Battery-full .path1:before {
  content: "\ebf1";
}
.icon-Battery-full .path2:before {
  content: "\ebf2";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Battery-half .path1:before {
  content: "\ebf3";
}
.icon-Battery-half .path2:before {
  content: "\ebf4";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Bluetooth .path1:before {
  content: "\ebf5";

  opacity: 0.3;
}
.icon-Bluetooth .path2:before {
  content: "\ebf6";
  margin-left: -1em;
}
.icon-Camera .path1:before {
  content: "\ebf7";
}
.icon-Camera .path2:before {
  content: "\ebf8";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Camera .path3:before {
  content: "\ebf9";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Cardboard-vr .path1:before {
  content: "\ebfa";

  opacity: 0.3;
}
.icon-Cardboard-vr .path2:before {
  content: "\ebfb";
  margin-left: -1em;
}
.icon-Cassete .path1:before {
  content: "\ebfc";

  opacity: 0.3;
}
.icon-Cassete .path2:before {
  content: "\ebfd";
  margin-left: -1em;
}
.icon-Cassete .path3:before {
  content: "\ebfe";
  margin-left: -1em;
}
.icon-CPU .path1:before {
  content: "\ebff";

  opacity: 0.3;
}
.icon-CPU .path2:before {
  content: "\ec00";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-CPU .path3:before {
  content: "\ec01";
  margin-left: -1em;
}
.icon-CPU .path4:before {
  content: "\ec02";
  margin-left: -1em;
}
.icon-CPU .path5:before {
  content: "\ec03";
  margin-left: -1em;
}
.icon-CPU .path6:before {
  content: "\ec04";
  margin-left: -1em;
}
.icon-CPU .path7:before {
  content: "\ec05";
  margin-left: -1em;
}
.icon-CPU .path8:before {
  content: "\ec06";
  margin-left: -1em;
}
.icon-CPU1:before {
  content: "\ec07";
}
.icon-222:before {
  content: "\ec07";
}
.icon-Diagnostics .path1:before {
  content: "\ec08";

  opacity: 0.3;
}
.icon-Diagnostics .path2:before {
  content: "\ec09";
  margin-left: -1em;
}
.icon-Diagnostics .path3:before {
  content: "\ec0a";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Display .path1:before {
  content: "\ec0b";

  opacity: 0.3;
}
.icon-Display .path2:before {
  content: "\ec0c";
  margin-left: -1em;
}
.icon-Display1 .path1:before {
  content: "\ec0d";

  opacity: 0.3;
}
.icon-Display1 .path2:before {
  content: "\ec0e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Display1 .path3:before {
  content: "\ec0f";
  margin-left: -1em;
}
.icon-Display2 .path1:before {
  content: "\ec10";

  opacity: 0.3;
}
.icon-Display2 .path2:before {
  content: "\ec11";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Display2 .path3:before {
  content: "\ec12";
  margin-left: -1em;
}
.icon-Gameboy .path1:before {
  content: "\ec13";

  opacity: 0.3;
}
.icon-Gameboy .path2:before {
  content: "\ec14";
  margin-left: -1em;
}
.icon-Gamepad .path1:before {
  content: "\ec15";

  opacity: 0.3;
}
.icon-Gamepad .path2:before {
  content: "\ec16";
  margin-left: -1em;
}
.icon-Gamepad1 .path1:before {
  content: "\ec17";

  opacity: 0.3;
}
.icon-Gamepad1 .path2:before {
  content: "\ec18";
  margin-left: -1em;
}
.icon-Generator .path1:before {
  content: "\ec19";

  opacity: 0.3;
}
.icon-Generator .path2:before {
  content: "\ec1a";
  margin-left: -1em;
}
.icon-Generator .path3:before {
  content: "\ec1b";
  margin-left: -1em;
}
.icon-Generator .path4:before {
  content: "\ec1c";
  margin-left: -1em;
}
.icon-Hard-drive .path1:before {
  content: "\ec1d";
}
.icon-Hard-drive .path2:before {
  content: "\ec1e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Headphones .path1:before {
  content: "\ec1f";

  opacity: 0.3;
}
.icon-Headphones .path2:before {
  content: "\ec20";
  margin-left: -1em;
}
.icon-Homepod .path1:before {
  content: "\ec21";

  opacity: 0.3;
}
.icon-Homepod .path2:before {
  content: "\ec22";
  margin-left: -1em;
}
.icon-iMac .path1:before {
  content: "\ec23";
}
.icon-iMac .path2:before {
  content: "\ec24";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-iMac .path3:before {
  content: "\ec25";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-iPhone-back:before {
  content: "\ec26";
}
.icon-iPhone-X .path1:before {
  content: "\ec27";

  opacity: 0.3;
}
.icon-iPhone-X .path2:before {
  content: "\ec28";
  margin-left: -1em;
}
.icon-iPhone-x-back:before {
  content: "\ec29";
}
.icon-Keyboard .path1:before {
  content: "\ec2a";

  opacity: 0.3;
}
.icon-Keyboard .path2:before {
  content: "\ec2b";
  margin-left: -1em;
}
.icon-Laptop .path1:before {
  content: "\ec2c";
}
.icon-Laptop .path2:before {
  content: "\ec2d";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Laptop-macbook .path1:before {
  content: "\ec2e";
}
.icon-Laptop-macbook .path2:before {
  content: "\ec2f";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-LTE .path1:before {
  content: "\ec30";

  opacity: 0.3;
}
.icon-LTE .path2:before {
  content: "\ec31";
  margin-left: -1em;
}
.icon-LTE1 .path1:before {
  content: "\ec32";

  opacity: 0.3;
}
.icon-LTE1 .path2:before {
  content: "\ec33";
  margin-left: -1em;
}
.icon-Mic .path1:before {
  content: "\ec34";
}
.icon-Mic .path2:before {
  content: "\ec35";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Midi .path1:before {
  content: "\ec36";

  opacity: 0.3;
}
.icon-Midi .path2:before {
  content: "\ec37";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Midi .path3:before {
  content: "\ec38";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Midi .path4:before {
  content: "\ec39";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Midi .path5:before {
  content: "\ec3a";
  margin-left: -1em;
}
.icon-Midi .path6:before {
  content: "\ec3b";
  margin-left: -1em;
}
.icon-Midi .path7:before {
  content: "\ec3c";
  margin-left: -1em;
}
.icon-Mouse .path1:before {
  content: "\ec3d";

  opacity: 0.3;
}
.icon-Mouse .path2:before {
  content: "\ec3e";
  margin-left: -1em;
}
.icon-Mouse .path3:before {
  content: "\ec3f";
  margin-left: -1em;
}
.icon-Phone .path1:before {
  content: "\ec40";
}
.icon-Phone .path2:before {
  content: "\ec41";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Phone .path3:before {
  content: "\ec42";
  margin-left: -1em;
}
.icon-Printer .path1:before {
  content: "\ec43";
}
.icon-Printer .path2:before {
  content: "\ec44";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Radio .path1:before {
  content: "\ec45";

  opacity: 0.3;
}
.icon-Radio .path2:before {
  content: "\ec46";
  margin-left: -1em;
}
.icon-Radio .path3:before {
  content: "\ec47";
  margin-left: -1em;
}
.icon-Radio .path4:before {
  content: "\ec48";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Radio .path5:before {
  content: "\ec49";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Router .path1:before {
  content: "\ec4a";
}
.icon-Router .path2:before {
  content: "\ec4b";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Router1 .path1:before {
  content: "\ec4c";
}
.icon-Router1 .path2:before {
  content: "\ec4d";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-SD-card:before {
  content: "\ec4e";
}
.icon-Server .path1:before {
  content: "\ec4f";

  opacity: 0.3;
}
.icon-Server .path2:before {
  content: "\ec50";
  margin-left: -1em;
}
.icon-Server .path3:before {
  content: "\ec51";
  margin-left: -1em;
}
.icon-Speaker .path1:before {
  content: "\ec52";
}
.icon-Speaker .path2:before {
  content: "\ec53";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Tablet .path1:before {
  content: "\ec54";
}
.icon-Tablet .path2:before {
  content: "\ec55";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-TV .path1:before {
  content: "\ec56";
}
.icon-TV .path2:before {
  content: "\ec57";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-TV .path3:before {
  content: "\ec58";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-TV1 .path1:before {
  content: "\ec59";
}
.icon-TV1 .path2:before {
  content: "\ec5a";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-USB .path1:before {
  content: "\ec5b";
}
.icon-USB .path2:before {
  content: "\ec5c";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-USB .path3:before {
  content: "\ec5d";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Usb-storage .path1:before {
  content: "\ec5e";
}
.icon-Usb-storage .path2:before {
  content: "\ec5f";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Video-camera .path1:before {
  content: "\ec60";
}
.icon-Video-camera .path2:before {
  content: "\ec61";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Watch .path1:before {
  content: "\ec62";
}
.icon-Watch .path2:before {
  content: "\ec63";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Watch .path3:before {
  content: "\ec64";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Watch .path4:before {
  content: "\ec65";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Watch1 .path1:before {
  content: "\ec66";

  opacity: 0.3;
}
.icon-Watch1 .path2:before {
  content: "\ec67";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Watch1 .path3:before {
  content: "\ec68";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Watch1 .path4:before {
  content: "\ec69";
  margin-left: -1em;
}
.icon-Wi-fi .path1:before {
  content: "\ec6a";

  opacity: 0.3;
}
.icon-Wi-fi .path2:before {
  content: "\ec6b";
  margin-left: -1em;
}
.icon-Adjust:before {
  content: "\ec6c";
}
.icon-Anchor-center .path1:before {
  content: "\ec6d";
}
.icon-Anchor-center .path2:before {
  content: "\ec6e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Anchor-center-down .path1:before {
  content: "\ec6f";

  opacity: 0.3;
}
.icon-Anchor-center-down .path2:before {
  content: "\ec70";
  margin-left: -1em;
}
.icon-Anchor-center-up .path1:before {
  content: "\ec71";

  opacity: 0.3;
}
.icon-Anchor-center-up .path2:before {
  content: "\ec72";
  margin-left: -1em;
}
.icon-Anchor-left .path1:before {
  content: "\ec73";

  opacity: 0.3;
}
.icon-Anchor-left .path2:before {
  content: "\ec74";
  margin-left: -1em;
}
.icon-Anchor-left-down .path1:before {
  content: "\ec75";

  opacity: 0.3;
}
.icon-Anchor-left-down .path2:before {
  content: "\ec76";
  margin-left: -1em;
}
.icon-Anchor-left-up .path1:before {
  content: "\ec77";

  opacity: 0.3;
}
.icon-Anchor-left-up .path2:before {
  content: "\ec78";
  margin-left: -1em;
}
.icon-Anchor-right .path1:before {
  content: "\ec79";

  opacity: 0.3;
}
.icon-Anchor-right .path2:before {
  content: "\ec7a";
  margin-left: -1em;
}
.icon-Anchor-right-down .path1:before {
  content: "\ec7b";

  opacity: 0.3;
}
.icon-Anchor-right-down .path2:before {
  content: "\ec7c";
  margin-left: -1em;
}
.icon-Anchor-right-up .path1:before {
  content: "\ec7d";

  opacity: 0.3;
}
.icon-Anchor-right-up .path2:before {
  content: "\ec7e";
  margin-left: -1em;
}
.icon-Arrows .path1:before {
  content: "\ec7f";

  opacity: 0.3;
}
.icon-Arrows .path2:before {
  content: "\ec80";
  margin-left: -1em;
}
.icon-Bezier-curve .path1:before {
  content: "\ec81";

  opacity: 0.3;
}
.icon-Bezier-curve .path2:before {
  content: "\ec82";
  margin-left: -1em;
}
.icon-Border:before {
  content: "\ec83";
}
.icon-Brush1 .path1:before {
  content: "\ec84";
}
.icon-Brush1 .path2:before {
  content: "\ec85";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Bucket .path1:before {
  content: "\ec86";
}
.icon-Bucket .path2:before {
  content: "\ec87";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Cap-1 .path1:before {
  content: "\ec88";
}
.icon-Cap-1 .path2:before {
  content: "\ec89";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Cap-2 .path1:before {
  content: "\ec8a";

  opacity: 0.3;
}
.icon-Cap-2 .path2:before {
  content: "\ec8b";
  margin-left: -1em;
}
.icon-Cap-3 .path1:before {
  content: "\ec8c";
}
.icon-Cap-3 .path2:before {
  content: "\ec8d";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Circle:before {
  content: "\ec8e";
}
.icon-Color:before {
  content: "\ec8f";
}
.icon-Color-profile .path1:before {
  content: "\ec90";

  opacity: 0.3;
}
.icon-Color-profile .path2:before {
  content: "\ec91";
  margin-left: -1em;
}
.icon-Component:before {
  content: "\ec92";
}
.icon-Crop .path1:before {
  content: "\ec93";

  opacity: 0.3;
}
.icon-Crop .path2:before {
  content: "\ec94";
  margin-left: -1em;
}
.icon-Difference .path1:before {
  content: "\ec95";
}
.icon-Difference .path2:before {
  content: "\ec96";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Edit .path1:before {
  content: "\ec97";
}
.icon-Edit .path2:before {
  content: "\ec98";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Eraser:before {
  content: "\ec99";
}
.icon-Flatten .path1:before {
  content: "\ec9a";
}
.icon-Flatten .path2:before {
  content: "\ec9b";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Flip-horizontal .path1:before {
  content: "\ec9c";

  opacity: 0.3;
}
.icon-Flip-horizontal .path2:before {
  content: "\ec9d";
  margin-left: -1em;
}
.icon-Flip-horizontal .path3:before {
  content: "\ec9e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Flip-vertical .path1:before {
  content: "\ec9f";

  opacity: 0.3;
}
.icon-Flip-vertical .path2:before {
  content: "\eca0";
  margin-left: -1em;
}
.icon-Flip-vertical .path3:before {
  content: "\eca1";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Horizontal .path1:before {
  content: "\eca2";
}
.icon-Horizontal .path2:before {
  content: "\eca3";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Image:before {
  content: "\eca4";
}
.icon-Interselect .path1:before {
  content: "\eca5";

  opacity: 0.3;
}
.icon-Interselect .path2:before {
  content: "\eca6";
  margin-left: -1em;
}
.icon-Join-1 .path1:before {
  content: "\eca7";
}
.icon-Join-1 .path2:before {
  content: "\eca8";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Join-2 .path1:before {
  content: "\eca9";
}
.icon-Join-2 .path2:before {
  content: "\ecaa";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Join-3 .path1:before {
  content: "\ecab";

  opacity: 0.3;
}
.icon-Join-3 .path2:before {
  content: "\ecac";
  margin-left: -1em;
}
.icon-Layers .path1:before {
  content: "\ecad";
}
.icon-Layers .path2:before {
  content: "\ecae";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Line .path1:before {
  content: "\ecaf";

  opacity: 0.3;
}
.icon-Line .path2:before {
  content: "\ecb0";
  margin-left: -1em;
}
.icon-Line .path3:before {
  content: "\ecb1";
  margin-left: -1em;
}
.icon-Magic .path1:before {
  content: "\ecb2";
}
.icon-Magic .path2:before {
  content: "\ecb3";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Mask .path1:before {
  content: "\ecb4";

  opacity: 0.3;
}
.icon-Mask .path2:before {
  content: "\ecb5";
  margin-left: -1em;
}
.icon-Patch .path1:before {
  content: "\ecb6";
}
.icon-Patch .path2:before {
  content: "\ecb7";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Patch .path3:before {
  content: "\ecb8";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Penruller .path1:before {
  content: "\ecb9";

  opacity: 0.3;
}
.icon-Penruller .path2:before {
  content: "\ecba";
  margin-left: -1em;
}
.icon-Pencil:before {
  content: "\ecbb";
}
.icon-Pen-tool-vector .path1:before {
  content: "\ecbc";
}
.icon-Pen-tool-vector .path2:before {
  content: "\ecbd";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Picker .path1:before {
  content: "\ecbe";
}
.icon-Picker .path2:before {
  content: "\ecbf";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Pixels .path1:before {
  content: "\ecc0";
}
.icon-Pixels .path2:before {
  content: "\ecc1";
  margin-left: -1em;
}
.icon-Pixels .path3:before {
  content: "\ecc2";
  margin-left: -1em;
}
.icon-Pixels .path4:before {
  content: "\ecc3";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Pixels .path5:before {
  content: "\ecc4";
  margin-left: -1em;
}
.icon-Pixels .path6:before {
  content: "\ecc5";
  margin-left: -1em;
}
.icon-Polygon:before {
  content: "\ecc6";
}
.icon-Position:before {
  content: "\ecc7";
}
.icon-Rectangle:before {
  content: "\ecc8";
}
.icon-Saturation:before {
  content: "\ecc9";
}
.icon-Select .path1:before {
  content: "\ecca";

  opacity: 0.3;
}
.icon-Select .path2:before {
  content: "\eccb";
  margin-left: -1em;
}
.icon-Sketch .path1:before {
  content: "\eccc";

  opacity: 0.3;
}
.icon-Sketch .path2:before {
  content: "\eccd";
  margin-left: -1em;
}
.icon-Stamp .path1:before {
  content: "\ecce";
}
.icon-Stamp .path2:before {
  content: "\eccf";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Substract .path1:before {
  content: "\ecd0";
}
.icon-Substract .path2:before {
  content: "\ecd1";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Target .path1:before {
  content: "\ecd2";

  opacity: 0.3;
}
.icon-Target .path2:before {
  content: "\ecd3";
  margin-left: -1em;
}
.icon-Triangle:before {
  content: "\ecd4";
}
.icon-Union:before {
  content: "\ecd5";
}
.icon-Vertical .path1:before {
  content: "\ecd6";
}
.icon-Vertical .path2:before {
  content: "\ecd7";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Zoom-minus .path1:before {
  content: "\ecd8";

  opacity: 0.3;
}
.icon-Zoom-minus .path2:before {
  content: "\ecd9";
  margin-left: -1em;
}
.icon-Zoom-minus .path3:before {
  content: "\ecda";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Zoom-plus .path1:before {
  content: "\ecdb";

  opacity: 0.3;
}
.icon-Zoom-plus .path2:before {
  content: "\ecdc";
  margin-left: -1em;
}
.icon-Zoom-plus .path3:before {
  content: "\ecdd";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Baking-glove .path1:before {
  content: "\ecde";
}
.icon-Baking-glove .path2:before {
  content: "\ecdf";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Bowl .path1:before {
  content: "\ece0";

  opacity: 0.3;
}
.icon-Bowl .path2:before {
  content: "\ece1";
  margin-left: -1em;
}
.icon-Chef .path1:before {
  content: "\ece2";

  opacity: 0.3;
}
.icon-Chef .path2:before {
  content: "\ece3";
  margin-left: -1em;
}
.icon-Cooking-book .path1:before {
  content: "\ece4";

  opacity: 0.09;
}
.icon-Cooking-book .path2:before {
  content: "\ece5";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Cooking-pot .path1:before {
  content: "\ece6";

  opacity: 0.3;
}
.icon-Cooking-pot .path2:before {
  content: "\ece7";
  margin-left: -1em;
}
.icon-Cutting-board .path1:before {
  content: "\ece8";

  opacity: 0.3;
}
.icon-Cutting-board .path2:before {
  content: "\ece9";
  margin-left: -1em;
}
.icon-Dinner .path1:before {
  content: "\ecea";

  opacity: 0.3;
}
.icon-Dinner .path2:before {
  content: "\eceb";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Dinner .path3:before {
  content: "\ecec";
  margin-left: -1em;
}
.icon-Dinner .path4:before {
  content: "\eced";
  margin-left: -1em;
}
.icon-Dinner .path5:before {
  content: "\ecee";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Dish .path1:before {
  content: "\ecef";
}
.icon-Dish .path2:before {
  content: "\ecf0";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Dishes .path1:before {
  content: "\ecf1";
}
.icon-Dishes .path2:before {
  content: "\ecf2";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Fork .path1:before {
  content: "\ecf3";

  opacity: 0.3;
}
.icon-Fork .path2:before {
  content: "\ecf4";
  margin-left: -1em;
}
.icon-Fork-spoon .path1:before {
  content: "\ecf5";
}
.icon-Fork-spoon .path2:before {
  content: "\ecf6";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Fork-spoon .path3:before {
  content: "\ecf7";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Fork-spoon .path4:before {
  content: "\ecf8";
  margin-left: -1em;
}
.icon-Fork-spoon-knife .path1:before {
  content: "\ecf9";
}
.icon-Fork-spoon-knife .path2:before {
  content: "\ecfa";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Fork-spoon-knife .path3:before {
  content: "\ecfb";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Fork-spoon-knife .path4:before {
  content: "\ecfc";
  margin-left: -1em;
}
.icon-Fork-spoon-knife .path5:before {
  content: "\ecfd";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Fork-spoon-knife .path6:before {
  content: "\ecfe";
  margin-left: -1em;
}
.icon-Frying-pan .path1:before {
  content: "\ecff";

  opacity: 0.3;
}
.icon-Frying-pan .path2:before {
  content: "\ed00";
  margin-left: -1em;
}
.icon-Grater .path1:before {
  content: "\ed01";

  opacity: 0.3;
}
.icon-Grater .path2:before {
  content: "\ed02";
  margin-left: -1em;
}
.icon-Kitchen-scale .path1:before {
  content: "\ed03";
}
.icon-Kitchen-scale .path2:before {
  content: "\ed04";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Knife .path1:before {
  content: "\ed05";

  opacity: 0.3;
}
.icon-Knife .path2:before {
  content: "\ed06";
  margin-left: -1em;
}
.icon-Knife1 .path1:before {
  content: "\ed07";

  opacity: 0.3;
}
.icon-Knife1 .path2:before {
  content: "\ed08";
  margin-left: -1em;
}
.icon-Knifefork .path1:before {
  content: "\ed09";
}
.icon-Knifefork .path2:before {
  content: "\ed0a";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Knifefork .path3:before {
  content: "\ed0b";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Knifefork .path4:before {
  content: "\ed0c";
  margin-left: -1em;
}
.icon-Knifefork1 .path1:before {
  content: "\ed0d";
}
.icon-Knifefork1 .path2:before {
  content: "\ed0e";
  margin-left: -1em;
}
.icon-Knifefork1 .path3:before {
  content: "\ed0f";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Knifefork1 .path4:before {
  content: "\ed10";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Ladle .path1:before {
  content: "\ed11";

  opacity: 0.3;
}
.icon-Ladle .path2:before {
  content: "\ed12";
  margin-left: -1em;
}
.icon-Rolling-pin .path1:before {
  content: "\ed13";
}
.icon-Rolling-pin .path2:before {
  content: "\ed14";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Saucepan .path1:before {
  content: "\ed15";
}
.icon-Saucepan .path2:before {
  content: "\ed16";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Shovel .path1:before {
  content: "\ed17";

  opacity: 0.3;
}
.icon-Shovel .path2:before {
  content: "\ed18";
  margin-left: -1em;
}
.icon-Sieve .path1:before {
  content: "\ed19";

  opacity: 0.3;
}
.icon-Sieve .path2:before {
  content: "\ed1a";
  margin-left: -1em;
}
.icon-Spoon .path1:before {
  content: "\ed1b";

  opacity: 0.3;
}
.icon-Spoon .path2:before {
  content: "\ed1c";
  margin-left: -1em;
}
.icon-Spoon .path3:before {
  content: "\ed1d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Active-call .path1:before {
  content: "\ed1e";
}
.icon-Active-call .path2:before {
  content: "\ed1f";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Address-card:before {
  content: "\ed20";
}
.icon-Add-user .path1:before {
  content: "\ed21";

  opacity: 0.3;
}
.icon-Add-user .path2:before {
  content: "\ed22";
  margin-left: -1em;
}
.icon-Adress-book .path1:before {
  content: "\ed23";

  opacity: 0.3;
}
.icon-Adress-book .path2:before {
  content: "\ed24";
  margin-left: -1em;
}
.icon-Adress-book1 .path1:before {
  content: "\ed25";

  opacity: 0.3;
}
.icon-Adress-book1 .path2:before {
  content: "\ed26";
  margin-left: -1em;
}
.icon-Archive:before {
  content: "\ed27";
}
.icon-Call1:before {
  content: "\ed28";
}
.icon-118:before {
  content: "\ed28";
}
.icon-Call:before {
  content: "\ed29";
}
.icon-Chat .path1:before {
  content: "\ed2a";

  opacity: 0.3;
}
.icon-Chat .path2:before {
  content: "\ed2b";
  margin-left: -1em;
}
.icon-Chat1 .path1:before {
  content: "\ed2c";

  opacity: 0.3;
}
.icon-Chat1 .path2:before {
  content: "\ed2d";
  margin-left: -1em;
}
.icon-Chat2:before {
  content: "\ed2e";
}
.icon-41:before {
  content: "\ed2e";
}
.icon-Chat3:before {
  content: "\ed2f";
}
.icon-51:before {
  content: "\ed2f";
}
.icon-Chat4:before {
  content: "\ed30";
}
.icon-6:before {
  content: "\ed30";
}
.icon-Chat-check .path1:before {
  content: "\ed31";

  opacity: 0.3;
}
.icon-Chat-check .path2:before {
  content: "\ed32";
  margin-left: -1em;
}
.icon-Chat-error .path1:before {
  content: "\ed33";
}
.icon-Chat-error .path2:before {
  content: "\ed34";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Chat-locked .path1:before {
  content: "\ed35";

  opacity: 0.3;
}
.icon-Chat-locked .path2:before {
  content: "\ed36";
  margin-left: -1em;
}
.icon-Chat-smile .path1:before {
  content: "\ed37";

  opacity: 0.3;
}
.icon-Chat-smile .path2:before {
  content: "\ed38";
  margin-left: -1em;
}
.icon-Clipboard-check .path1:before {
  content: "\ed39";

  opacity: 0.3;
}
.icon-Clipboard-check .path2:before {
  content: "\ed3a";
  margin-left: -1em;
}
.icon-Clipboard-check .path3:before {
  content: "\ed3b";
  margin-left: -1em;
}
.icon-Clipboard-list .path1:before {
  content: "\ed3c";

  opacity: 0.3;
}
.icon-Clipboard-list .path2:before {
  content: "\ed3d";
  margin-left: -1em;
}
.icon-Clipboard-list .path3:before {
  content: "\ed3e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Clipboard-list .path4:before {
  content: "\ed3f";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Clipboard-list .path5:before {
  content: "\ed40";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Clipboard-list .path6:before {
  content: "\ed41";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Clipboard-list .path7:before {
  content: "\ed42";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Clipboard-list .path8:before {
  content: "\ed43";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Contact:before {
  content: "\ed44";
}
.icon-110:before {
  content: "\ed44";
}
.icon-Delete-user .path1:before {
  content: "\ed45";

  opacity: 0.3;
}
.icon-Delete-user .path2:before {
  content: "\ed46";
  margin-left: -1em;
}
.icon-Dial-numbers .path1:before {
  content: "\ed47";

  opacity: 0.3;
}
.icon-Dial-numbers .path2:before {
  content: "\ed48";
  margin-left: -1em;
}
.icon-Dial-numbers .path3:before {
  content: "\ed49";
  margin-left: -1em;
}
.icon-Dial-numbers .path4:before {
  content: "\ed4a";
  margin-left: -1em;
}
.icon-Dial-numbers .path5:before {
  content: "\ed4b";
  margin-left: -1em;
}
.icon-Dial-numbers .path6:before {
  content: "\ed4c";
  margin-left: -1em;
}
.icon-Dial-numbers .path7:before {
  content: "\ed4d";
  margin-left: -1em;
}
.icon-Dial-numbers .path8:before {
  content: "\ed4e";
  margin-left: -1em;
}
.icon-Dial-numbers .path9:before {
  content: "\ed4f";
  margin-left: -1em;
}
.icon-Flag .path1:before {
  content: "\ed50";
}
.icon-Flag .path2:before {
  content: "\ed51";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Forward1:before {
  content: "\ed52";
}
.icon-Group .path1:before {
  content: "\ed53";

  opacity: 0.3;
}
.icon-Group .path2:before {
  content: "\ed54";
  margin-left: -1em;
}
.icon-Group-chat .path1:before {
  content: "\ed55";
}
.icon-Group-chat .path2:before {
  content: "\ed56";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Incoming-box .path1:before {
  content: "\ed57";
}
.icon-Incoming-box .path2:before {
  content: "\ed58";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Incoming-box .path3:before {
  content: "\ed59";
  margin-left: -1em;
}
.icon-Incoming-call .path1:before {
  content: "\ed5a";

  opacity: 0.3;
}
.icon-Incoming-call .path2:before {
  content: "\ed5b";
  margin-left: -1em;
}
.icon-Incoming-mail .path1:before {
  content: "\ed5c";
}
.icon-Incoming-mail .path2:before {
  content: "\ed5d";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Mail:before {
  content: "\ed5e";
}
.icon-Mail-:before {
  content: "\ed5f";
}
.icon-Mail-attachment .path1:before {
  content: "\ed60";

  opacity: 0.3;
}
.icon-Mail-attachment .path2:before {
  content: "\ed61";
  margin-left: -1em;
}
.icon-Mail-box .path1:before {
  content: "\ed62";
}
.icon-Mail-box .path2:before {
  content: "\ed63";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Mail-error .path1:before {
  content: "\ed64";
}
.icon-Mail-error .path2:before {
  content: "\ed65";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Mail-heart .path1:before {
  content: "\ed66";

  opacity: 0.3;
}
.icon-Mail-heart .path2:before {
  content: "\ed67";
  margin-left: -1em;
}
.icon-Mail-locked .path1:before {
  content: "\ed68";
}
.icon-Mail-locked .path2:before {
  content: "\ed69";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Mail-notification .path1:before {
  content: "\ed6a";
}
.icon-Mail-notification .path2:before {
  content: "\ed6b";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Mail-opened .path1:before {
  content: "\ed6c";

  opacity: 0.3;
}
.icon-Mail-opened .path2:before {
  content: "\ed6d";
  margin-left: -1em;
}
.icon-Mail-unocked .path1:before {
  content: "\ed6e";

  opacity: 0.3;
}
.icon-Mail-unocked .path2:before {
  content: "\ed6f";
  margin-left: -1em;
}
.icon-Missed-call .path1:before {
  content: "\ed70";

  opacity: 0.3;
}
.icon-Missed-call .path2:before {
  content: "\ed71";
  margin-left: -1em;
}
.icon-Outgoing-box .path1:before {
  content: "\ed72";
}
.icon-Outgoing-box .path2:before {
  content: "\ed73";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Outgoing-box .path3:before {
  content: "\ed74";
  margin-left: -1em;
}
.icon-Outgoing-call .path1:before {
  content: "\ed75";

  opacity: 0.3;
}
.icon-Outgoing-call .path2:before {
  content: "\ed76";
  margin-left: -1em;
}
.icon-Outgoing-mail .path1:before {
  content: "\ed77";
}
.icon-Outgoing-mail .path2:before {
  content: "\ed78";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Readed-mail .path1:before {
  content: "\ed79";

  opacity: 0.3;
}
.icon-Readed-mail .path2:before {
  content: "\ed7a";
  margin-left: -1em;
}
.icon-Reply:before {
  content: "\ed7b";
}
.icon-Reply-all .path1:before {
  content: "\ed7c";

  opacity: 0.3;
}
.icon-Reply-all .path2:before {
  content: "\ed7d";
  margin-left: -1em;
}
.icon-Right:before {
  content: "\ed7e";
}
.icon-RSS .path1:before {
  content: "\ed7f";
}
.icon-RSS .path2:before {
  content: "\ed80";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-RSS .path3:before {
  content: "\ed81";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Safe-chat .path1:before {
  content: "\ed82";

  opacity: 0.3;
}
.icon-Safe-chat .path2:before {
  content: "\ed83";
  margin-left: -1em;
}
.icon-Send:before {
  content: "\ed84";
}
.icon-Sending-mail .path1:before {
  content: "\ed85";

  opacity: 0.3;
}
.icon-Sending-mail .path2:before {
  content: "\ed86";
  margin-left: -1em;
}
.icon-Sending .path1:before {
  content: "\ed87";
}
.icon-Sending .path2:before {
  content: "\ed88";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Share .path1:before {
  content: "\ed89";

  opacity: 0.3;
}
.icon-Share .path2:before {
  content: "\ed8a";
  margin-left: -1em;
}
.icon-Shield-thunder:before {
  content: "\ed8b";
}
.icon-Shield-user:before {
  content: "\ed8c";
}
.icon-Snoozed-mail .path1:before {
  content: "\ed8d";
}
.icon-Snoozed-mail .path2:before {
  content: "\ed8e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Spam:before {
  content: "\ed8f";
}
.icon-Thumbtack .path1:before {
  content: "\ed90";
}
.icon-Thumbtack .path2:before {
  content: "\ed91";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Urgent-mail .path1:before {
  content: "\ed92";

  opacity: 0.3;
}
.icon-Urgent-mail .path2:before {
  content: "\ed93";
  margin-left: -1em;
}
.icon-Write .path1:before {
  content: "\ed94";
}
.icon-Write .path2:before {
  content: "\ed95";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Backspace .path1:before {
  content: "\ed96";

  opacity: 0.3;
}
.icon-Backspace .path2:before {
  content: "\ed97";
  margin-left: -1em;
}
.icon-CMD:before {
  content: "\ed98";
}
.icon-Code1 .path1:before {
  content: "\ed99";
}
.icon-Code1 .path2:before {
  content: "\ed9a";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Commit .path1:before {
  content: "\ed9b";

  opacity: 0.3;
}
.icon-Commit .path2:before {
  content: "\ed9c";
  margin-left: -1em;
}
.icon-Compiling .path1:before {
  content: "\ed9d";

  opacity: 0.3;
}
.icon-Compiling .path2:before {
  content: "\ed9e";
  margin-left: -1em;
}
.icon-Control:before {
  content: "\ed9f";
}
.icon-Done-circle .path1:before {
  content: "\eda0";

  opacity: 0.3;
}
.icon-Done-circle .path2:before {
  content: "\eda1";
  margin-left: -1em;
}
.icon-Error-circle .path1:before {
  content: "\eda2";

  opacity: 0.3;
}
.icon-Error-circle .path2:before {
  content: "\eda3";
  margin-left: -1em;
}
.icon-Git2 .path1:before {
  content: "\eda4";

  opacity: 0.3;
}
.icon-Git2 .path2:before {
  content: "\eda5";
  margin-left: -1em;
}
.icon-Git2 .path3:before {
  content: "\eda6";
  margin-left: -1em;
}
.icon-Git3 .path1:before {
  content: "\eda7";

  opacity: 0.3;
}
.icon-Git3 .path2:before {
  content: "\eda8";
  margin-left: -1em;
}
.icon-Git3 .path3:before {
  content: "\eda9";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Git3 .path4:before {
  content: "\edaa";
  margin-left: -1em;
}
.icon-Git3 .path5:before {
  content: "\edab";
  margin-left: -1em;
}
.icon-Git3 .path6:before {
  content: "\edac";
  margin-left: -1em;
}
.icon-Git .path1:before {
  content: "\edad";

  opacity: 0.3;
}
.icon-Git .path2:before {
  content: "\edae";
  margin-left: -1em;
}
.icon-Git .path3:before {
  content: "\edaf";
  margin-left: -1em;
}
.icon-Git .path4:before {
  content: "\edb0";
  margin-left: -1em;
}
.icon-Git1 .path1:before {
  content: "\edb1";
}
.icon-Git1 .path2:before {
  content: "\edb2";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Git1 .path3:before {
  content: "\edb3";
  margin-left: -1em;
}
.icon-Git1 .path4:before {
  content: "\edb4";
  margin-left: -1em;
}
.icon-Github .path1:before {
  content: "\edb5";
}
.icon-Github .path2:before {
  content: "\edb6";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Info-circle .path1:before {
  content: "\edb7";

  opacity: 0.3;
}
.icon-Info-circle .path2:before {
  content: "\edb8";
  margin-left: -1em;
}
.icon-Info-circle .path3:before {
  content: "\edb9";
  margin-left: -1em;
}
.icon-Left-circle .path1:before {
  content: "\edba";

  opacity: 0.3;
}
.icon-Left-circle .path2:before {
  content: "\edbb";
  margin-left: -1em;
}
.icon-Loading:before {
  content: "\edbc";
}
.icon-Lock-circle .path1:before {
  content: "\edbd";

  opacity: 0.3;
}
.icon-Lock-circle .path2:before {
  content: "\edbe";
  margin-left: -1em;
}
.icon-Lock-overturning .path1:before {
  content: "\edbf";

  opacity: 0.3;
}
.icon-Lock-overturning .path2:before {
  content: "\edc0";
  margin-left: -1em;
}
.icon-Minus .path1:before {
  content: "\edc1";

  opacity: 0.3;
}
.icon-Minus .path2:before {
  content: "\edc2";
  margin-left: -1em;
}
.icon-Option .path1:before {
  content: "\edc3";

  opacity: 0.3;
}
.icon-Option .path2:before {
  content: "\edc4";
  margin-left: -1em;
}
.icon-Plus .path1:before {
  content: "\edc5";

  opacity: 0.3;
}
.icon-Plus .path2:before {
  content: "\edc6";
  margin-left: -1em;
}
.icon-Puzzle:before {
  content: "\edc7";
}
.icon-Question-circle .path1:before {
  content: "\edc8";

  opacity: 0.3;
}
.icon-Question-circle .path2:before {
  content: "\edc9";
  margin-left: -1em;
}
.icon-Right-circle .path1:before {
  content: "\edca";

  opacity: 0.3;
}
.icon-Right-circle .path2:before {
  content: "\edcb";
  margin-left: -1em;
}
.icon-Settings1 .path1:before {
  content: "\edcc";

  opacity: 0.3;
}
.icon-Settings1 .path2:before {
  content: "\edcd";
  margin-left: -1em;
}
.icon-Shift:before {
  content: "\edce";
}
.icon-Spy .path1:before {
  content: "\edcf";
}
.icon-Spy .path2:before {
  content: "\edd0";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Stop:before {
  content: "\edd1";
}
.icon-Terminal .path1:before {
  content: "\edd2";
}
.icon-Terminal .path2:before {
  content: "\edd3";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Thunder-circle .path1:before {
  content: "\edd4";

  opacity: 0.3;
}
.icon-Thunder-circle .path2:before {
  content: "\edd5";
  margin-left: -1em;
}
.icon-Time-schedule .path1:before {
  content: "\edd6";
}
.icon-Time-schedule .path2:before {
  content: "\edd7";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Warning-1-circle .path1:before {
  content: "\edd8";

  opacity: 0.3;
}
.icon-Warning-1-circle .path2:before {
  content: "\edd9";
  margin-left: -1em;
}
.icon-Warning-1-circle .path3:before {
  content: "\edda";
  margin-left: -1em;
}
.icon-Warning-2 .path1:before {
  content: "\eddb";

  opacity: 0.3;
}
.icon-Warning-2 .path2:before {
  content: "\eddc";
  margin-left: -1em;
}
.icon-Warning-2 .path3:before {
  content: "\eddd";
  margin-left: -1em;
}
.icon-Brassiere:before {
  content: "\edde";
}
.icon-Briefcase .path1:before {
  content: "\eddf";
}
.icon-Briefcase .path2:before {
  content: "\ede0";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Cap .path1:before {
  content: "\ede1";

  opacity: 0.3;
}
.icon-Cap .path2:before {
  content: "\ede2";
  margin-left: -1em;
}
.icon-Crown .path1:before {
  content: "\ede3";

  opacity: 0.3;
}
.icon-Crown .path2:before {
  content: "\ede4";
  margin-left: -1em;
}
.icon-Dress .path1:before {
  content: "\ede5";

  opacity: 0.3;
}
.icon-Dress .path2:before {
  content: "\ede6";
  margin-left: -1em;
}
.icon-Hanger:before {
  content: "\ede7";
}
.icon-Hat .path1:before {
  content: "\ede8";
}
.icon-Hat .path2:before {
  content: "\ede9";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Panties:before {
  content: "\edea";
}
.icon-Shirt .path1:before {
  content: "\edeb";

  opacity: 0.3;
}
.icon-Shirt .path2:before {
  content: "\edec";
  margin-left: -1em;
}
.icon-Shoes .path1:before {
  content: "\eded";
}
.icon-Shoes .path2:before {
  content: "\edee";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Shorts:before {
  content: "\edef";
}
.icon-Sneakers .path1:before {
  content: "\edf0";

  opacity: 0.3;
}
.icon-Sneakers .path2:before {
  content: "\edf1";
  margin-left: -1em;
}
.icon-Socks .path1:before {
  content: "\edf2";

  opacity: 0.3;
}
.icon-Socks .path2:before {
  content: "\edf3";
  margin-left: -1em;
}
.icon-Sun-glasses .path1:before {
  content: "\edf4";

  opacity: 0.3;
}
.icon-Sun-glasses .path2:before {
  content: "\edf5";
  margin-left: -1em;
}
.icon-Tie .path1:before {
  content: "\edf6";
}
.icon-Tie .path2:before {
  content: "\edf7";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-T-Shirt:before {
  content: "\edf8";
}
.icon-Celcium .path1:before {
  content: "\edf9";
}
.icon-Celcium .path2:before {
  content: "\edfa";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Cloud:before {
  content: "\edfb";
}
.icon-18:before {
  content: "\edfb";
}
.icon-Cloud1 .path1:before {
  content: "\edfc";

  opacity: 0.3;
}
.icon-Cloud1 .path2:before {
  content: "\edfd";
  margin-left: -1em;
}
.icon-Cloud-fog .path1:before {
  content: "\edfe";
}
.icon-Cloud-fog .path2:before {
  content: "\edff";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Cloud-sun .path1:before {
  content: "\ee00";

  opacity: 0.3;
}
.icon-Cloud-sun .path2:before {
  content: "\ee01";
  margin-left: -1em;
}
.icon-Cloud-wind .path1:before {
  content: "\ee02";
}
.icon-Cloud-wind .path2:before {
  content: "\ee03";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Cloud-wind .path3:before {
  content: "\ee04";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Cloudy .path1:before {
  content: "\ee05";

  opacity: 0.3;
}
.icon-Cloudy .path2:before {
  content: "\ee06";
  margin-left: -1em;
}
.icon-Cloudy-night .path1:before {
  content: "\ee07";

  opacity: 0.3;
}
.icon-Cloudy-night .path2:before {
  content: "\ee08";
  margin-left: -1em;
}
.icon-Day-rain .path1:before {
  content: "\ee09";

  opacity: 0.3;
}
.icon-Day-rain .path2:before {
  content: "\ee0a";
  margin-left: -1em;
}
.icon-Fahrenheit .path1:before {
  content: "\ee0b";
}
.icon-Fahrenheit .path2:before {
  content: "\ee0c";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Fog:before {
  content: "\ee0d";
}
.icon-Moon:before {
  content: "\ee0e";
}
.icon-Night-fog .path1:before {
  content: "\ee0f";
}
.icon-Night-fog .path2:before {
  content: "\ee10";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Night-rain .path1:before {
  content: "\ee11";

  opacity: 0.3;
}
.icon-Night-rain .path2:before {
  content: "\ee12";
  margin-left: -1em;
}
.icon-Rain .path1:before {
  content: "\ee13";
}
.icon-Rain .path2:before {
  content: "\ee14";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Rain1 .path1:before {
  content: "\ee15";
}
.icon-Rain1 .path2:before {
  content: "\ee16";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Rain2 .path1:before {
  content: "\ee17";

  opacity: 0.3;
}
.icon-Rain2 .path2:before {
  content: "\ee18";
  margin-left: -1em;
}
.icon-Rainbow .path1:before {
  content: "\ee19";

  opacity: 0.3;
}
.icon-Rainbow .path2:before {
  content: "\ee1a";
  margin-left: -1em;
}
.icon-Snow .path1:before {
  content: "\ee1b";
}
.icon-Snow .path2:before {
  content: "\ee1c";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Snow1 .path1:before {
  content: "\ee1d";
}
.icon-Snow1 .path2:before {
  content: "\ee1e";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Snow2 .path1:before {
  content: "\ee1f";

  opacity: 0.3;
}
.icon-Snow2 .path2:before {
  content: "\ee20";
  margin-left: -1em;
}
.icon-Snow3:before {
  content: "\ee21";
}
.icon-Storm .path1:before {
  content: "\ee22";
}
.icon-Storm .path2:before {
  content: "\ee23";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Sun .path1:before {
  content: "\ee24";
}
.icon-Sun .path2:before {
  content: "\ee25";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Sun-fog .path1:before {
  content: "\ee26";
}
.icon-Sun-fog .path2:before {
  content: "\ee27";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Suset .path1:before {
  content: "\ee28";
}
.icon-Suset .path2:before {
  content: "\ee29";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Suset1 .path1:before {
  content: "\ee2a";
}
.icon-Suset1 .path2:before {
  content: "\ee2b";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Temperature-empty:before {
  content: "\ee2c";
}
.icon-Temperature-full:before {
  content: "\ee2d";
}
.icon-Temperature-half:before {
  content: "\ee2e";
}
.icon-Thunder .path1:before {
  content: "\ee2f";

  opacity: 0.3;
}
.icon-Thunder .path2:before {
  content: "\ee30";
  margin-left: -1em;
}
.icon-Thunder-night .path1:before {
  content: "\ee31";

  opacity: 0.3;
}
.icon-Thunder-night .path2:before {
  content: "\ee32";
  margin-left: -1em;
}
.icon-Umbrella .path1:before {
  content: "\ee33";
}
.icon-Umbrella .path2:before {
  content: "\ee34";
  margin-left: -1em;

  opacity: 0.3;
}
.icon-Wind .path1:before {
  content: "\ee35";
}
.icon-Wind .path2:before {
  content: "\ee36";
  margin-left: -1em;
  opacity: 0.3;
}
